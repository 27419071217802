import React, { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { getItemWithExpiration } from '../../lib/utils';
import { PeriodNavigatorType } from '../Date/PeriodNavigator';
import MemberClassHistoryComponentsHeader from './MemberClassHistoryComponentsHeader';
import MemberClassHistoryComponentsGrid from './MemberClassHistoryComponentsGrid';
import { MemberClassHistorySchedule, MonthlySchedule, YearSchedule } from '../../services/useCenterQueryService';
import { useQuery } from '@tanstack/react-query';
import { getMemberScheduleHistory } from '../../api/center';

type Props = {
    coachId?: string;
    memberId?: string;
    onClickRow?: (row: MemberClassHistorySchedule) => void;
};

const MemberClassHistoryComponents = React.memo(({ coachId, memberId, onClickRow }: Props) => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [classHistoryDate, setClassHistoryDate] = useState<string>(dayjs().toISOString());

    //  수업기록 조회 API : (GET)
    const [historyData, setHistoryData] = useState<MonthlySchedule | undefined>(undefined);
    const [monthlyData, setMonthlyData] = useState<MemberClassHistorySchedule[] | undefined>([]);
    const [yearlyData, setYearlyData] = useState<YearSchedule | undefined>({});
    const [totalData, setTotalData] = useState<YearSchedule[] | undefined>([]);
    const _ = useQuery(
        ['getMemberScheduleHistoryQuery', classHistoryDate],
        async () =>
            await getMemberScheduleHistory(
                authObject?.centerId,
                coachId,
                memberId,
                dayjs(classHistoryDate).format('YYYY-MM-DD'),
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const thisMonth: any = res.data.thisMonth;
                    const thisYear = res.data.thisYear;
                    const entire = res.data.totalView;

                    setHistoryData(res.data);

                    if (thisMonth) {
                        setMonthlyData(thisMonth);
                    }

                    if (thisYear) {
                        setYearlyData(thisYear);
                    }

                    if (entire) {
                        setTotalData(entire);
                    }
                }
            },

            enabled: !!authorization && !!authObject?.centerId && !!coachId && !!memberId,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    const [type, setType] = useState<PeriodNavigatorType>('thisMonth');
    const onClickPrev = useCallback(
        (type: PeriodNavigatorType) => {
            switch (type) {
                case 'thisMonth':
                    setClassHistoryDate(dayjs(classHistoryDate).subtract(1, 'month').toISOString());
                    break;
                case 'thisYear':
                    setClassHistoryDate(dayjs(classHistoryDate).subtract(1, 'year').toISOString());
                    break;
                default:
                    break;
            }
        },
        [classHistoryDate]
    );

    const onClickNext = useCallback(
        (type: PeriodNavigatorType) => {
            switch (type) {
                case 'thisMonth':
                    setClassHistoryDate(dayjs(classHistoryDate).add(1, 'month').toISOString());
                    break;
                case 'thisYear':
                    setClassHistoryDate(dayjs(classHistoryDate).add(1, 'year').toISOString());
                    break;
                default:
                    break;
            }
        },
        [classHistoryDate]
    );

    const monthLength = historyData?.thisMonthScheduleCount;
    const yearLength = historyData?.thisYearScheduleCount;
    const allLength = historyData?.allScheduleCount;

    return (
        <div className="flex flex-col w-full h-full justify-center items-center">
            <MemberClassHistoryComponentsHeader
                currentDate={classHistoryDate}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
                onClickType={(type) => setType(type)}
                type={type}
                monthLength={monthLength}
                yearLength={yearLength}
                allLength={allLength}
            />
            <MemberClassHistoryComponentsGrid
                type={type}
                currentDate={classHistoryDate}
                monthlyData={monthlyData}
                yearlyData={yearlyData}
                totalData={totalData}
                onClickRow={onClickRow}
            />
        </div>
    );
});

export default MemberClassHistoryComponents;
