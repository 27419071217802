import React, { useState } from 'react';
import { Col, Row } from '../../../../lib/utils';
import { CaptionLight, CaptionRegular, Head4 } from '../../../../lib/font';
import { colors } from '../../../../lib/colors';
import { CodeValueType } from '../../../../types/types';
import NewRoundedDropDown from '../../../../components/DropDown/NewRoundedDropDown';
import ResetButton from '../../../../components/Button/ResetButton';
import Grid, { GridColProps } from '../../../../components/Grid/Grid';
import { MemberMembershipDataType } from '../../../../services/useMembershipQueryService';
import dayjs from 'dayjs';
import NewColorRoundButton from '../../../../components/Button/NewColorRoundButton';
import { useNavigate } from 'react-router-dom';
let firstOptions: CodeValueType[] = [
    { code: 'ALL', value: '전체 보기' },
    { code: 'ACTIVE', value: '활성 수강권' },
    { code: 'EXPIRED', value: '만료 수강권' },
];

let secondOptions: CodeValueType[] = [
    { code: 'RECENT', value: '최근 발급순' },
    { code: 'OLD', value: '오래된 순' },
    { code: 'MANY', value: '총 회차 많은 순' },
    { code: 'FEW', value: '총 회차 적은 순' },
];

type Props = {
    firstSortType: CodeValueType;
    onFirstOptionChange?: (item: CodeValueType) => void;
    secondSortType: CodeValueType;
    onSecondOptionChange?: (item: CodeValueType) => void;
    thirdSortType: CodeValueType;
    onThirdOptionChange?: (item: CodeValueType) => void;
    coachNameList: CodeValueType<string>[];
    historyList?: MemberMembershipDataType[];
    // 수강권 이전하기 클릭
    onClickChangeMembership: (data: MemberMembershipDataType[]) => void;
    // 초기화 버튼 클릭
    onClickReset?: () => void;
};

const NewClassPassHistoryComponent = ({
    firstSortType,
    onFirstOptionChange,
    secondSortType,
    onSecondOptionChange,
    thirdSortType,
    onThirdOptionChange,
    coachNameList,
    historyList,
    onClickChangeMembership,
    onClickReset,
}: Props) => {
    const navigate = useNavigate();
    const [colDefs, setColDefs] = useState<GridColProps[]>([
        {
            field: 'coachProfile',
            headerName: '강사이름',
            align: 'center',
            width: 140,
            isProfile: true,
        },
        {
            field: 'memberProfile',
            headerName: '회원이름',
            align: 'center',
            width: 140,
            isProfile: true,
        },
        {
            field: 'createdAt',
            headerName: '발급 날짜',
            align: 'center',
            width: 172,
            dateFormat: 'YYYY년 M월 D일',
            type: 'date',
        },
        {
            field: 'currentSession',
            headerName: '현재 / 총 회차',
            align: 'center',
            width: 126,
            customFormat(value, row) {
                return `${value} / ${row.totalSession}`;
            },
        },
        {
            field: 'pricePerSchedule',
            headerName: '회당 가격',
            align: 'center',
            width: 140,
            type: 'money',
        },
        {
            field: 'totalPrice',
            headerName: '판매가격',
            align: 'center',
            width: 140,
            type: 'money',
        },
        {
            field: 'activationDate',
            headerName: '기간',
            align: 'center',
            width: 192,

            customComponent(value, row) {
                return (
                    <div className="flex flex-col items-start w-full h-full justify-start">
                        <CaptionRegular className="text-LAVEL_3">
                            {dayjs(value).format('YYYY년 MM월 DD일')}
                        </CaptionRegular>
                        <CaptionRegular className="text-LAVEL_3">
                            {`~ ${dayjs(row.expirationDate).format('YYYY년 MM월 DD일')} ${dayjs(
                                row.expirationDate
                            ).diff(dayjs(value), 'day')}일`}
                        </CaptionRegular>
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: '상태',
            align: 'center',
            width: 91,
            booleanComponent: {
                fn: (value) => {
                    return value.status === 'active';
                },
                trueComponent: (
                    <div className="flex w-full justify-center">
                        <Col className="flex flex-col justify-center bg-BLUE_50 w-[46px] h-[30px] rounded-[4px]">
                            <CaptionRegular className="text-BLUE_500">활성</CaptionRegular>
                        </Col>
                    </div>
                ),
                falseComponent: (
                    <div className="flex w-full justify-center">
                        <Col className="flex flex-col justify-center bg-ERROR_50 w-[46px] h-[30px] rounded-[4px]">
                            <CaptionRegular className="text-ERROR">만료</CaptionRegular>
                        </Col>
                    </div>
                ),
            },
        },
    ]);

    return (
        <Col className="flex ">
            <Row className="w-[1192px] flex items-center justify-between mb-[20px]">
                <Head4 style={{ color: colors.LAVEL_4 }}>수강권 발급 기록</Head4>
                <Row>
                    <NewRoundedDropDown
                        width={132}
                        selectData={firstSortType}
                        datas={firstOptions}
                        onClickSort={(item) => {
                            onFirstOptionChange && onFirstOptionChange(item);
                        }}
                        mr={16}
                    />
                    <NewRoundedDropDown
                        width={132}
                        selectData={thirdSortType}
                        datas={coachNameList}
                        onClickSort={(item) => {
                            onThirdOptionChange && onThirdOptionChange(item);
                        }}
                        mr={16}
                    />
                    <NewRoundedDropDown
                        width={136}
                        selectData={secondSortType}
                        datas={secondOptions}
                        onClickSort={(item) => {
                            onSecondOptionChange && onSecondOptionChange(item);
                        }}
                        mr={16}
                    />
                    <ResetButton callBack={onClickReset} />
                </Row>
            </Row>
            <Grid
                colDefs={colDefs}
                rowData={historyList || []}
                isCheckColumn
                centerTopComponent="pagination"
                leftTopCustomComponent={(_, selectedData) => {
                    const disabled = selectedData && selectedData.length === 0;
                    return (
                        <NewColorRoundButton
                            text={'선택 수강권 이전하기'}
                            bgColor={disabled ? 'WHITE_400' : 'primary'}
                            textColor={disabled ? 'WHITE_700' : 'white'}
                            className={disabled ? 'border-[1px] border-solid border-WHITE_700 cursor-not-allowed' : ''}
                            onClick={() => {
                                onClickChangeMembership(selectedData);
                            }}
                            fontType={'CaptionRegular'}
                            width={137}
                            height={24}
                            disabled={disabled}
                        />
                    );
                }}
                rightTopCustomComponent={(data) => {
                    return (
                        <CaptionLight>{`총 발급 수 :${historyList?.length}건 /  필터된 수강권 : ${data.length}건`}</CaptionLight>
                    );
                }}
                onClickRow={(data) => {
                    navigate('/member/detail/membership', {
                        state: {
                            membershipId: data._id,
                            memberName: data.memberName,
                            coachName: data.coachName,
                            memberId: data.memberId,
                            coachId: data.coachId,
                        },
                    });
                }}
            />
        </Col>
    );
};

export default NewClassPassHistoryComponent;
