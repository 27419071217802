import React, { useState } from 'react';
import Grid, { GridColProps } from './Grid';
import { DaySchedule, MonthSchedule, YearSchedule } from '../../services/useCenterQueryService';
import { Body2Regular, Body3Light } from '../../lib/font';
import { Col, Row } from '../../lib/utils';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import { PeriodNavigatorType } from '../Date/PeriodNavigator';

type Props = {
    colDefs: GridColProps[];
    rowData: YearSchedule | YearSchedule[] | undefined;
    currentYear: string;
    type: PeriodNavigatorType;
    onClickRow?: (row: any) => void;
};

const MultiGrid = ({ colDefs, rowData, currentYear, type, onClickRow }: Props) => {
    // const mergedData = () => {
    //   if (type === "all") {
    //     const yearData = (rowData as YearSchedule[])?.find(
    //       (year) => year[currentYear]
    //     );

    //     if (yearData) {
    //       const a = [{ ...yearData[currentYear], ...yearData[currentYear] }][0];
    //       const keys = Object.keys(a);
    //       const values = Object.values(a);
    //       const result = keys.map((key, i) => {
    //         return values[i];
    //       });
    //       return result;
    //     }
    //   }
    // };
    // const _data = mergedData();
    const data: any = type === 'thisYear' ? rowData && (rowData as YearSchedule)[currentYear] : rowData;

    const [expandedMonths, setExpandedMonths] = useState<number[]>([]);

    const toggleMonth = (month: number) => {
        setExpandedMonths((prev) => (prev.includes(month) ? prev.filter((m) => m !== month) : [...prev, month]));
    };

    // 1부터 12까지의 월
    const months = Array.from({ length: 12 }, (_, i) => i + 1);

    // daysData 를 map 으로 돌면서 하나의 배열ㄹ로 만들어서 리턴
    const convertDaysData = (daysData: DaySchedule[]) => {
        let result: any[] = [];

        daysData.map((dayData: DaySchedule) => {
            const dayKey = Object.keys(dayData)[0];
            const dayDataValue = dayData[dayKey];

            result.push(...dayDataValue);
        });

        return result;
    };
    //
    const years = type === 'all' && Object.values(rowData as YearSchedule[]).map((year) => Object.keys(year));
    const yearValues = rowData as YearSchedule[];

    return (
        <div className="w-full">
            {data ? (
                type === 'thisYear' ? (
                    months.map((month) => {
                        const monthKey = String(month);
                        const monthData = data?.find((d: any) => Object.keys(d)[0] === monthKey) as MonthSchedule;
                        if (!monthData) {
                            return undefined;
                        }

                        const daysData = monthData[monthKey];
                        const daysLength = daysData.length;
                        const isExpanded = expandedMonths.includes(month);
                        const dayData = convertDaysData(daysData);

                        return (
                            <div key={month} className="mb-[28px]">
                                <Row onClick={() => toggleMonth(month)} className=" cursor-pointer">
                                    <Body2Regular className="w-[40px] mr-[28px]  ">{month}월</Body2Regular>
                                    <Body2Regular className="mr-[100px]">{`총 ${daysLength}회`}</Body2Regular>
                                    <SvgIcon
                                        name={isExpanded ? 'SvgUpStrokeArrow' : 'SvgDownStrokeArrow'}
                                        size={20}
                                        fill={colors.WHITE_50}
                                        stroke={colors.primary}
                                    />
                                </Row>
                                {isExpanded && <Grid colDefs={colDefs} rowData={dayData} onClickRow={onClickRow} />}
                            </div>
                        );
                    })
                ) : (
                    years &&
                    years.map((yearArray) => {
                        return yearArray.map((year) => {
                            const yearData = yearValues.find((y) => Object.keys(y)[0] === year);
                            if (!yearData) {
                                return undefined;
                            }
                            const yearKey = Object.keys(yearData)[0];
                            const yearValue = yearData[yearKey];
                            const monthValues = Object.values(yearValue);
                            return months.map((month) => {
                                const monthKey = month;
                                const monthData = monthValues.find((m: any) => m[monthKey]);
                                if (!monthData) {
                                    return undefined;
                                }

                                const daysData = monthData[monthKey];
                                const daysLength = daysData.length;
                                const isExpanded = expandedMonths.includes(month);
                                const dayData = convertDaysData(daysData as unknown as DaySchedule[]);
                                return (
                                    <div key={month} className="mb-[28px]">
                                        <Row onClick={() => toggleMonth(month)} className="cursor-pointer">
                                            <Body2Regular className="w-[100px] mr-[28px]  ">
                                                {year}년 {month}월
                                            </Body2Regular>
                                            <Body2Regular className="mr-[100px]">{`총 ${daysLength}회`}</Body2Regular>
                                            <SvgIcon
                                                name={isExpanded ? 'SvgUpStrokeArrow' : 'SvgDownStrokeArrow'}
                                                size={20}
                                                fill={colors.WHITE_50}
                                                stroke={colors.primary}
                                            />
                                        </Row>
                                        {isExpanded && (
                                            <Grid colDefs={colDefs} rowData={dayData} onClickRow={onClickRow} />
                                        )}
                                    </div>
                                );
                            });
                        });
                    })
                )
            ) : (
                <Col className="flex w-full h-[240px] bg-white border-[1px] border-BLUE_100 border-solid rounded-[8px] justify-center items-center">
                    <SvgIcon name={'SvgError'} size={18} fill={colors.GREY_400} className="mb-[12px]" />
                    <Body3Light className="text-LAVEL_2 ">아직 수업 기록이 없는 회원이에요!</Body3Light>
                </Col>
            )}
        </div>
    );
};

export default MultiGrid;
