// apiService.js

import { Membership } from '../api/center';
import {
    getAuthentificationPhoneNumber,
    getClassFeeSettlement,
    getClassFeeSettlementForMembership,
    GetClassFeeSettlementParamsType,
    getOneMember,
} from '../api/member';
import { MemberShipApiType } from '../api/memberShip';
import { MemberMembershipDataType, MemberScheduleDataType } from './useMembershipQueryService';

export const fetchOneMemberProfile = (
    params: {
        centerId: string;
        memberId: string;
    },
    authorization: string
) => ({
    queryKey: ['getCenterMemberList', params.centerId, params.memberId],
    queryFn: () => getOneMember(params.centerId, params.memberId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.member ?? undefined;
    },
});

export const fetchAuthentificationPhoneNumber = (
    params: { centerId: string; phoneNumber: string },
    authorization: string
) => ({
    queryKey: ['getAuthentificationPhoneNumber', params.phoneNumber],
    queryFn: () =>
        getAuthentificationPhoneNumber({
            centerId: params.centerId,
            phoneNumber: params.phoneNumber,
            accessToken: authorization,
        }),
    onSuccess: (data: any) => {
        const result: FetchAuthentificationPhoneNumberResponse = data?.data?.result ?? {};
        // 데이터 정제 로직
        return result;
    },
});
// getClassFeeSettlement
export const fetchGetClassFeeSettlement = (params: GetClassFeeSettlementParamsType, authorization: string) => ({
    queryKey: ['getClassFeeSettlement', params.startDate, params.selectedCoach],
    queryFn: () =>
        getClassFeeSettlement({
            centerId: params.centerId,
            startDate: params.startDate,
            endDate: params.endDate,
            selectedCoach: params.selectedCoach,
            accessToken: authorization,
        }),
    onSuccess: (data: any) => {
        // // 데이터 정제 로직
        const result: FetchGetClassFeeSettlementResponse = data.data ?? {};

        return result;
    },
});
// getClassFeeSettlement
export const fetchGetClassFeeSettlementForMembership = (
    params: GetClassFeeSettlementParamsType,
    authorization: string
) => ({
    queryKey: ['getClassFeeSettlementForMembershipQuery', params.startDate, params.selectedCoach],
    queryFn: () =>
        getClassFeeSettlementForMembership({
            centerId: params.centerId,
            startDate: params.startDate,
            endDate: params.endDate,
            selectedCoach: params.selectedCoach,
            accessToken: authorization,
        }),
    onSuccess: (data: any) => {
        // // 데이터 정제 로직
        const result: FetchGetClassFeeSettlementResponse = data.data ?? {};

        return result;
    },
});

export type FetchAuthentificationPhoneNumberResponse = {
    coachName: string;
    centerName: string;
    weight: WeightRecord;
    height: number;
    birthday: string;
    gender: 'male' | 'female';
};

type WeightRecord = {
    value: number;
    recordDate: string; // ISO 날짜 문자열로 정의
};

export type MemberProfile = {
    inviteCode: string;
    isInfo: boolean;
    isRequest: boolean;
    isDeleted: boolean;
    isCanceled: boolean; // 추가된 필드
    memberType: 'temp' | 'regular' | 'premium'; // 추가된 필드, 예시로 Enum 형태로 정의 가능
    name: string;
    phoneNumber: string;
    gender: 'male' | 'female';
    height: number;
    weight: WeightRecord[];
    address: string; // 추가된 필드
    memo: string;
    centerId: string; // 추가된 필드
    created: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    birthday: string;
    exerciseVolume: string;
    profileImageUrl: string;
    profileColorType: string;
    profileName: string;
    coachProfileImageUrl: string;
    coachProfileColorType: string;
    coachProfileName: string;
    coachId: string;
    coachName: string;
    id: string;
};

// 전체 데이터 타입
export interface FetchGetClassFeeSettlementResponse {
    coachs: Coach[];
    summaryData: GetClassFeeSettlementSummaryData;
    // tableDataByMember: GetClassFeeSettlementTableData[];
    tableDataBySchedule: MemberMembershipDataType[];
    tableDataByMembership: GetClassFeeSettlementTableDataForMembership[];
    filters: Filters;
}

interface Coach {
    coachId: string;
    coachName: string;
}
export interface GetClassFeeSettlementSummaryData {
    totalScheduleFee: number; // 총 수업료
    totalPricePerSchedule: number; // 총 등록 단가
    totalRemainSession: number; // 총 남은 수업 수
    totalAttendanceScheduleCount: number; // 총 완료 수업 수
    totalNoshowScheduleCount: number; // 총 노쇼 수
    totalScheduleMemberCount: number; // 수업한 회원 수
}
// 테이블 데이터 타입
export interface GetClassFeeSettlementTableData {
    memberId: string;
    memberName: string;
    coachId: string;
    coachName: string;
    attendanceScheduleCount: number;
    noshowScheduleCount: number;
    remainSession: number;
    memberTotalScheduleFee: number;
    commission: number;
    commissionType: 'fixed' | 'percentage';
    membershipInfo: MembershipInfo;
    scheduleRecord: ClassFeeSettlementScheduleRecord;
}

export interface GetClassFeeSettlementTableDataForMembership {
    memberId: string;
    memberName: string;
    coachId: string;
    coachName: string;
    attendanceScheduleCount: number; // 완료
    noshowScheduleCount: number; // 노쇼
    remainSession: number; // 남은 수업수
    memberTotalScheduleFee: number; // 수업 정산액
    commission: number;
    commissionType: 'percentage' | 'fixed';
    pricePerSchedule: number; // 회당 가격
    activationDate: string;
    expirationDate: string;
    membershipName: string; // 수강권 이름
    membershipId: string;
    createdAt: string; // 발급 일자
    membershipInfo: MembershipInfo; // 수강권 정보
    scheduleRecord: ScheduleRecord;
    isCustomedCommission: boolean;
}

export type ScheduleRecord = {
    allSchedules: MemberScheduleDataType[];
    coachName: string;
    memberName: string;
    membershipName: string;
    membershipBGColor: string[];
};

export interface ClassFeeSettlementScheduleRecord {
    allSchedules: MemberScheduleDataType[];
    coachName: string;
    memberName: string;
    membershipBGColor?: string[];
    membershipName: string;
}

// 회원 정보 타입
interface MemberInfo {
    memberName: string;
    profileImageUrl: string;
    age: number;
    isNewSales: boolean;
    gender: string;
}

// 멤버십 세부 정보 타입
export interface MembershipInfo {
    coachName: string;
    memberInfo: MemberInfo;
    membership: Membership;
}
// 필터 데이터 타입
interface Filters {
    memberList: {
        memberId: string;
        memberName: string;
    }[];
    membershiptemplateList: {
        membershipTemplateId: string;
        membershipTemplateName: string;
    }[];
}
