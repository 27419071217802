import React, { useEffect, useMemo, useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../lib/utils';
import { colors } from '../lib/colors';
import { Body1Bold, Body1Regular, Body3Bold, Body3Regular, CaptionLight, CaptionRegular, Head4 } from '../lib/font';
import SvgIcon from '../components/SvgIcon';
import FormTextInput from '../components/InputBox /FormTextInput';
import Dot from '../components/Dot';
import NewTextInputDropDown from '../components/DropDown/NewTextInputDropDown';
import { CodeValueType } from '../types/types';
import { fetchCoachNameList } from '../services/useCenterQueryService';
import useMultipleQueries from '../hook/useMultipleQueries';
import NewColorRoundButton from '../components/Button/NewColorRoundButton';

import { fetchAuthentificationPhoneNumber } from '../services/useMemberQueryService';
import useSingleQuery from '../hook/useSingleQuery';
import MemoTextInput from '../components/TextInput/MemoTextInput';
import ModalMembershipCreate from '../components/MemberProfile/ModalMembershipCreate';
import ModalRegisterPass from './ClassPass/ModalRegisterPass/ModalRegisterPass';
import { DATE_REGEXP, GENDER_TYPE, GENDERCODETYPE } from '../lib/varialbe';
import { MemberShipApiType } from '../api/memberShip';
import MembershipCard from '../components/MemberProfile/MembershipCard';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { CustomCheckbox } from '../components/Grid/Grid';
import useMemberMutationService from '../services/useMemberMutationService';
import { CreateTempMemberParamsType } from '../api/member';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    createAlert: any;
};

const MemberTemporaryPage = ({ createAlert }: Props) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const navigate = useNavigate();
    const [isModalMembershipCreateVisible, setIsModalMembershipCreateVisible] = useState(false);
    const [isRegisterPassModal, setIsRegisterPassModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const queryClient = useQueryClient();

    // 담당강사 CodeValueType
    const [selectCoach, setSelectCoach] = useState<CodeValueType | undefined>(
        // dropDownCoachList && dropDownCoachList.find((item: CodeValueType) => item.code === memberProfile?.coachId)
        undefined
    );
    // 이름
    const [name, setName] = useState('');

    // 휴대폰 번호 / 000-0000-0000
    const [phoneNum, setPhoneNum] = useState('');

    // 생년월일 / 00000000
    const [birth, setBirth] = useState('');
    // 성별 / 남자, 여자 CodeValueType
    const [gender, setGender] = useState<CodeValueType<GENDERCODETYPE> | undefined>(GENDER_TYPE[2]);
    // 키
    const [height, setHeight] = useState('');
    // 시작체중 / 00
    const [startWeight, setStartWeight] = useState('');
    // 주소
    const [address, setAddress] = useState('');
    // 메모
    const [memo, setMemo] = useState('');

    // 수강권 리스트
    const [membershipList, setMembershipList] = useState<MemberShipApiType[]>([]);

    // 중복체크 disabled
    const [isDuplicateDisabled, setIsDuplicateDisabled] = useState(true);
    // 중복체크 완료
    const [isDuplicateCheck, setIsDuplicateCheck] = useState(false);
    // 앱 연결 요청 메시지 여부
    const [isSendMessage, setIsSendMessage] = useState(true);

    const { refinedResults } = useMultipleQueries([
        {
            fetchFn: fetchCoachNameList,
            params: { centerId: authObject?.centerId, isAllConcat: true },
            enabled: !!authObject?.centerId,
        },
    ]);
    const { refinedResult: asyncResult } = useSingleQuery({
        fetchFn: fetchAuthentificationPhoneNumber,
        params: { centerId: authObject.centerId, phoneNumber: phoneNum },
        enabled: false,
        // enabled: !!authObject?.centerId && !!fetchPhoneNumber,
    });
    const { createTempMemberMutation } = useMemberMutationService({
        onCreateTempMemberSuccess(data) {
            const memberId = data?.id;
            createAlert('회원 등록이 완료되었어요!', '회원 등록이 완료되었어요!', false, '프로필 바로가기', () => {
                navigate('/member/detail', { state: { memberId: memberId } });
            });
            //
            queryClient.invalidateQueries({ queryKey: ['getCenterMemberListAPIQuery'] });
        },
    });

    const dropDownCoachList = useMemo(
        () => (refinedResults?.[0]?.data as CodeValueType<string>[]) ?? [],
        [refinedResults]
    );
    //

    // 중복 체크 버튼
    const onClickDuplicateCheck = async () => {
        const result: any = await asyncResult.refetch();
        const status = result?.data?.status;

        if (status === 202) {
            setIsDuplicateCheck(true);
            createAlert(
                '신규 등록이 가능한 번호에요!',
                '새로운 회원 등록이 가능한 번호에요! ',
                false,
                '확인',
                () => {}
            );
        } else if (status === 413) {
            //FetchAuthentificationPhoneNumberResponse

            const memberId = result.data?.data?.duplicateMember.id;
            createAlert(
                '이미 센터에 등록된 회원이에요!',
                '이미 등록 완료된 회원이에요! 등록된 회원이 아니라면,\n번호가 정확히 기입되었는지 다시 한번 확인해 주세요!',
                true,
                '확인',
                () => {
                    setIsDuplicateCheck(false);
                }
                // true,
                // '프로필 바로가기',
                // () => {
                //     navigate('/member/detail', { state: { memberId: memberId } });
                // }
            );
        }
    };

    // 수강권 등록 버튼 클릭
    const onClickMembershipCreate = () => {
        // 회원 이름 없는경우 createAlert
        if (!name) {
            createAlert('이름을 입력해주세요!', '회원 이름을 입력해주세요!', true, '확인', () => {});
            return;
        }
        // 담당강사 없는경우 createAlert
        if (!selectCoach) {
            createAlert('담당 강사를 선택해주세요!', '담당 강사를 선택해주세요!', true, '확인', () => {});
            return;
        }

        setIsModalMembershipCreateVisible(true);
    };
    // 수강권 등록 완료시
    const onMembershipCreateSuccess = (membership: MemberShipApiType) => {
        setMembershipList([...membershipList, membership]);
        setIsModalMembershipCreateVisible(false);
        createAlert('수강권 등록이 완료되었어요!', '수강권 등록이 완료되었어요!', false, '확인', () => {});
    };

    // 회원 등록완료
    const onClickMemberCreate = () => {
        // 이름 2글자 이상, 5글자 이하 체크,빈값 체크
        if (name.length < 2 || name.length > 5 || !name) {
            createAlert('이름을 확인해주세요!', '이름은 2 ~ 5글자로 입력해주세요!', true, '확인', () => {});
            return;
        }

        // 담당강사 체크
        else if (!selectCoach) {
            createAlert('담당 강사를 선택해주세요!', '담당 강사를 선택해주세요!', true, '확인', () => {});
            return;
        }
        // 휴대폰 번호 체크
        else if (!phoneNum) {
            createAlert('휴대폰 번호를 입력해주세요!', '휴대폰 번호를 입력해주세요!', true, '확인', () => {});
            return;
        }
        // 휴대폰 번호 정규식 체크
        else if (!isDuplicateCheck) {
            createAlert('휴대폰 번호 중복체크를 해주세요!', '휴대폰 번호 중복체크를 해주세요!', true, '확인', () => {});
            return;
        }
        // 생년월일 체크
        else if (birth && !DATE_REGEXP.test(birth)) {
            createAlert('생년월일을 정확히 입력해주세요!', '생년월일을 정확히 입력해주세요!', true, '확인', () => {});
            return;
        }

        const params: CreateTempMemberParamsType = {
            centerId: authObject.centerId,
            tempMember: {
                name: name,
                coachId: selectCoach?.code,
                phoneNumber: phoneNum,
                gender: gender?.code ?? '',
                height: height ? Number(height) : undefined,
                weight: startWeight ? Number(startWeight) : undefined,
                address: address,
                memo: memo,
                birthday: birth ? dayjs(birth).toISOString() : undefined,
                membershipInfo: membershipList,
                isSendAlimtalkToMember: isSendMessage,
            },
        };

        createTempMemberMutation.mutate(params);
    };

    // 중복체크 disabled
    // 휴대폰 번호 정규식에 맞지 않을경우 setDisabled(true)
    useEffect(() => {
        const phoneNumReg = /^\d{3}-\d{4}-\d{4}$/;
        if (phoneNumReg.test(phoneNum)) {
            setIsDuplicateDisabled(false);
        } else {
            setIsDuplicateDisabled(true);
        }

        if (phoneNum) {
            if (phoneNum.length === 10) {
                setPhoneNum(phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
            }
            if (phoneNum.length === 13) {
                setPhoneNum(phoneNum.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
            }
        }
    }, [phoneNum]);
    // // 생년월일 '-'추가 정제로직
    useEffect(() => {
        if (birth) {
            if (birth.length === 8) {
                setBirth(birth.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
            }

            if (birth.length === 9) {
                setBirth(birth.replace(/-/g, ''));
            }
        }
    }, [birth]);

    // 회원 다수 등록하기
    const onManyMemberRegister = () => {
        window.open('https://mustmove.notion.site/5bcbea791bfb4230b6fab4a77cd54534', '_blank', 'noopener,noreferrer');
    };

    return (
        <Col
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: colors.WHITE_200,
                marginTop: 64,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Col className="flex w-[1192px] mt-[48px] ">
                {/* 헤더 */}
                <Row className="flex w-full mb-[32px]">
                    <Row className="flex w-full items-center justify-between">
                        <Row>
                            <Head4 className=" mr-[32px]">회원 등록하기</Head4>
                            <Row className=" items-center cursor-pointer" onClick={onManyMemberRegister}>
                                <SvgIcon
                                    name={'SvgQuestion'}
                                    size={16}
                                    stroke={colors.primary}
                                    fill={colors.trans}
                                    className="mr-[8px]"
                                />
                                <CaptionRegular className=" text-BLUE_500">
                                    한번에 다수의 회원을 등록하는 방법
                                </CaptionRegular>
                            </Row>
                        </Row>
                        <Row className=" items-center cursor-pointer" onClick={() => window.history.back()}>
                            <SvgIcon
                                name={'SvgLeftStrokeArrow'}
                                size={16}
                                stroke={colors.black}
                                fill={colors.trans}
                                className="mr-[13px]"
                            />
                            <Body3Regular>뒤로가기</Body3Regular>
                        </Row>
                    </Row>
                </Row>
                {/* 본문 */}
                <Col className="bg-WHITE_50 border border-solid border-BLUE_100 py-[56px] px-[80px] rounded-[16px]">
                    <Body1Bold className="text-LAVEL_4 mb-[56px]">회원 정보 입력</Body1Bold>
                    {/* 이름, 담당강사 */}
                    <Row className="mb-[88px]">
                        <FormTextInput
                            title="이름"
                            placeholder="2 ~ 5글자로 입력해주세요!"
                            value={name}
                            onChangeText={setName}
                            width={320}
                            isRequired
                            mr={140}
                            maxLength={5}
                        />
                        {/* 담당강사 */}
                        <Col>
                            <Row className={`flex mb-[8px]`}>
                                <Body3Regular className=" text-LAVEL_4 mr-[4px]">담당 강사</Body3Regular>
                                <Dot size={4} color="ERROR" className=" self-start mr-[12px]" />
                            </Row>
                            <NewTextInputDropDown
                                selectData={selectCoach}
                                datas={dropDownCoachList ?? []}
                                onClickSort={(data) => {
                                    setSelectCoach(data);
                                }}
                                width={300}
                                fontType={'Body2Regular'}
                                placeholder="담당 강사를 선택해주세요"
                            />
                        </Col>
                    </Row>
                    {/* 휴대폰번호 */}
                    <Row className="mb-[88px]">
                        <FormTextInput
                            title="휴대폰 번호"
                            placeholder="000-0000-0000"
                            value={phoneNum}
                            onChangeText={setPhoneNum}
                            width={320}
                            isRequired
                            mr={12}
                            subTitle="정확한 번호를 입력해주셔야 회원님과 라포 연결이 가능해요!"
                            maxLength={13}
                        />
                        <NewColorRoundButton
                            bgColor={isDuplicateDisabled ? 'WHITE_700' : 'primary'}
                            textColor="white"
                            text="중복 체크"
                            onClick={onClickDuplicateCheck}
                            width={96}
                            height={36}
                            className=" self-end"
                            disabled={isDuplicateDisabled}
                        />
                    </Row>
                    {/* 생년월일, 성별 */}
                    <Row className="mb-[88px]">
                        <FormTextInput
                            title="생년월일"
                            placeholder="0000-00-00"
                            value={birth}
                            onChangeText={setBirth}
                            width={320}
                            mr={140}
                            maxLength={10}
                        />
                        <Col>
                            <Body3Regular className=" text-LAVEL_4 mb-[12px]">성별</Body3Regular>

                            <NewTextInputDropDown
                                datas={GENDER_TYPE}
                                selectData={gender}
                                onClickSort={(item) => {
                                    setGender(item);
                                }}
                                fontType="Body2Regular"
                                width={300}
                            />
                        </Col>
                    </Row>
                    {/* 생년월일, 성별 */}
                    <Row className="mb-[88px]">
                        <FormTextInput
                            title="키"
                            placeholder="172"
                            value={height}
                            onChangeText={setHeight}
                            width={320}
                            mr={140}
                            maxLength={5}
                        />
                        <FormTextInput
                            title="시작체중"
                            placeholder="70"
                            value={startWeight}
                            onChangeText={setStartWeight}
                            width={320}
                            mr={140}
                            maxLength={10}
                        />
                    </Row>
                    {/* 주소 */}
                    <FormTextInput
                        title="주소"
                        placeholder="주소를 입력해주세요"
                        value={address}
                        onChangeText={setAddress}
                        width={640}
                        mb={88}
                    />
                    {/* 메모 */}
                    <Col className="mb-[88px]">
                        <Body3Regular className=" text-LAVEL_4">메모</Body3Regular>
                        <CaptionLight className="text-LAVEL_2 mb-[12px]">
                            관리자님과 담당 강사님만 확인할 수 있는 정보에요!{' '}
                        </CaptionLight>
                        <MemoTextInput
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            height={136}
                            width={780}
                            placeholder="회원님에 대해 기억해야 할 내용들을 작성해주세요!"
                        />
                    </Col>
                    {/* 수강권 */}
                    <Body3Regular className=" text-LAVEL_4">수강권</Body3Regular>
                    <CaptionLight className="text-LAVEL_2 ">
                        {`회원님이 ‘라포 앱'으로 수업 신청을 하기 위해 필요해요!`}
                    </CaptionLight>
                    <CaptionLight className="text-LAVEL_2 mb-[12px]">
                        {`회원 등록 후, [회원 프로필 →  수강권 정보] 또는 강사님의 라포 앱으로도 등록이 가능해요!`}
                    </CaptionLight>
                    {/* 수강권 리스트 */}
                    <Row className="mb-[84px] overflow-auto">
                        {membershipList?.map((membership: MemberShipApiType, index: number) => (
                            <MembershipCard
                                index={index}
                                membership={membership}
                                onClickDetailPage={() => {}}
                                // onClickDetailPage={() =>
                                //     navigate('/member/detail/membership', {
                                //         state: {
                                //             membershipId: membership._id,
                                //             memberName: memberName,
                                //             coachName: coachName,
                                //             memberId: memberId,
                                //             coachId: coachId,
                                //         },
                                //     })
                                // }
                                onClickDelete={(_index: number) => {
                                    const cloneMembershipList = cloneDeep(membershipList);
                                    const newMembershipList = cloneMembershipList.filter((_, i) => i !== _index);
                                    setMembershipList(newMembershipList);
                                }}
                            />
                        ))}
                        <Col
                            className="flex flex-col w-[310px] h-[160px] border-BLUE_200 border-spacing-50 border-dashed border-[1px] bg-BLUE_50 justify-center items-center rounded-[8px] flex-shrink-0 cursor-pointer"
                            onClick={onClickMembershipCreate}
                        >
                            <NewColorRoundButton
                                text="수강권 등록하기"
                                fontType={'CaptionRegular'}
                                width={112}
                                height={24}
                                bgColor="primary"
                                textColor="white"
                            />
                        </Col>
                    </Row>
                    <Row className=" items-center justify-between">
                        <Row className=" items-start">
                            <CustomCheckbox
                                checked={isSendMessage}
                                onChange={() => {
                                    setIsSendMessage((prev) => !prev);
                                }}
                                size={24}
                                className="mr-[16px]"
                            />
                            <Col>
                                <Row>
                                    <Body3Regular className="text-LAVEL_3 mr-[4px]">회원님께</Body3Regular>
                                    <Body3Bold className="text-LAVEL_3 mr-[4px]">라포 앱 연결 요청 메시지</Body3Bold>
                                    <Body3Regular>보내기</Body3Regular>
                                </Row>
                                <CaptionLight className="text-LAVEL_3">
                                    회원님께 메시지가 오지 않았다면, 휴대폰 번호가 정확하게 입력되었는지 확인해 주세요!
                                </CaptionLight>
                            </Col>
                        </Row>
                        {/* GNB 콘텐츠 */}
                        <Col
                            className={`flex  items-center justify-center rounded-[48px] w-[192px] h-[64px]  ${
                                isDisabled
                                    ? 'bg-[#A5ABB5] text-[#EDF4FF] cursor-not-allowed'
                                    : 'bg-gradient-to-r from-[#3D73DD] to-[#6327E2] text-[#FDFEFF] cursor-pointer'
                            } `}
                            aria-disabled={isDisabled}
                            onClick={onClickMemberCreate}
                        >
                            <Body1Regular>회원 등록 완료</Body1Regular>
                        </Col>
                    </Row>
                </Col>
            </Col>
            {isModalMembershipCreateVisible && (
                <ModalMembershipCreate
                    setIsVisible={setIsModalMembershipCreateVisible}
                    onClickCreateNewMembership={() => {
                        setIsRegisterPassModal(true);
                    }}
                    memberName={name}
                    coachName={selectCoach?.value}
                    coachId={selectCoach?.code}
                    memberId={undefined}
                    createAlert={createAlert}
                    onMembershipCreateSuccess={onMembershipCreateSuccess}
                />
            )}
            {isRegisterPassModal && (
                <ModalRegisterPass
                    modalVisible={isRegisterPassModal}
                    setModalVisible={setIsRegisterPassModal}
                    createAlert={createAlert}
                    onSuccessCreate={() => {
                        // queryClient.invalidateQueries({
                        //     queryKey: ['getMembershipTemplatesListAPIQuery'],
                        // });
                    }}
                />
            )}
        </Col>
    );
};

export default MemberTemporaryPage;
