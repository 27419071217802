import React, { useEffect } from 'react';
import { Col, Row } from '../../lib/utils';
import { Body2Regular, Body3Regular, CaptionRegular, Head3, Head4 } from '../../lib/font';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import ProfileImage from '../ProfileImage';
import { MemberProfile } from '../../services/useMemberQueryService';
import NewWhiteSquareButton from '../Button/NewWhiteSquareButton';
import dayjs from 'dayjs';
import TrashButton from '../Button/TrashButton';
import NewWhiteRoundButton from '../Button/NewWhiteRoundButton';
import NewColorRoundButton from '../Button/NewColorRoundButton';

const MemberProfileComponents = ({
    memberId,
    memberProfile,
    createAlert,
    onClickChangeCoach, // 담당 강사 변경 모달
    onClickDeleteMember, // 회원 삭제 모달
    onClickMoveToFeed, // 수업 피드 바로가기
    onClickEditProfile, // 프로필 편집
}: {
    memberId: string;
    memberProfile?: MemberProfile;
    createAlert: any;
    onClickChangeCoach: () => void;
    onClickDeleteMember: () => void;
    onClickMoveToFeed: () => void;
    onClickEditProfile: () => void;
}) => {
    const birthDay = memberProfile?.birthday ? dayjs(memberProfile?.birthday).format('YYYY/MM/DD') : '-';
    const name = memberProfile?.name ? memberProfile?.name : '-';
    const coachName = memberProfile?.coachName ? memberProfile?.coachName : '-';
    const gender = memberProfile?.gender ? (memberProfile?.gender === 'male' ? '남자' : '여자') : '-';
    const phoneNumber = memberProfile?.phoneNumber ? memberProfile?.phoneNumber : '-';
    const memo = memberProfile?.memo ? memberProfile?.memo : '-';

    const height = memberProfile?.height ? memberProfile?.height : '-';
    const weight =
        memberProfile?.weight && memberProfile?.weight?.length > 0
            ? memberProfile?.weight[0]?.value
                ? memberProfile?.weight[0]?.value
                : '-'
            : '-';
    const address = memberProfile?.address ? memberProfile?.address : '-';
    const memberType = memberProfile?.memberType;
    const profileImageUrl = memberProfile?.profileImageUrl;
    const createdAt = memberProfile?.createdAt;

    return (
        <div className="flex flex-col w-full h-full justify-center items-center mb-[80px] mt-[80px]">
            <Row className="justify-between w-[1192px] items-center mb-[24px]">
                <Head4>회원 프로필</Head4>
                <div
                    className="flex flex-row h-[36px] border-[1px] border-BLUE_200 border-solid rounded-[48px] px-[16px] justify-center items-center bg-white"
                    onClick={() => window.history.back()}
                >
                    <SvgIcon
                        name="SvgLeftStrokeArrow"
                        stroke={colors.primary}
                        size={14}
                        fill="none"
                        className="mr-[16px]"
                    />
                    <Body3Regular style={{ color: colors.primary }}>뒤로가기</Body3Regular>
                </div>
            </Row>
            <Row className="flex border-[1px] border-solid border-BLUE_200 rounded-[8px] w-[1192px] p-[56px] bg-white mb-[16px]">
                {/* 왼쪽 프로필 */}
                <Col className="flex mr-[90px]">
                    <ProfileImage imageUrl={profileImageUrl} type={'VeryBig'} className="mb-[16px]" />
                    <Head3 className="mb-[40px] text-LAVEL_4">{name}</Head3>
                    <Row className=" items-center mb-[12px] w-full">
                        <Body2Regular className="mr-[4px] text-LAVEL_4">앱 사용</Body2Regular>
                        <Col
                            className={`${
                                memberType === 'temp' ? 'bg-WHITE_700' : 'bg-primary'
                            } rounded-full justify-center items-center w-[18px] h-[18px]`}
                        >
                            {memberType === 'temp' ? (
                                <SvgIcon name={'SvgX'} size={14} fill={colors.trans} stroke={colors.white} />
                            ) : (
                                <SvgIcon name={'SvgCheck'} size={14} fill={colors.trans} stroke={colors.white} />
                            )}
                        </Col>
                    </Row>
                    <Body2Regular className="mb-[12px] text-LAVEL_4">{`담당 강사 : ${coachName}`}</Body2Regular>
                    <NewWhiteSquareButton
                        text="담당 강사 변경"
                        onClick={onClickChangeCoach}
                        className="flex self-start px-[16px] py-[3px] "
                        mb={102}
                        fontType="CaptionLight"
                    />
                    <CaptionRegular className=" text-LAVEL_2">
                        {`등록일: ${dayjs(createdAt).format('YYYY년 MM월 DD일')}`}
                    </CaptionRegular>
                </Col>
                {/* 오른쪽 프로필 */}
                <Col className="flex">
                    {/* 휴대폰 번호, 생년월일, 성별 */}
                    <Row className="mb-[40px]">
                        <Col className="mr-[80px]">
                            <Body3Regular className=" text-LAVEL_1">휴대폰 번호</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{phoneNumber}</Body2Regular>
                        </Col>
                        <Col className="mr-[80px]">
                            <Body3Regular className=" text-LAVEL_1">생년월일</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{birthDay}</Body2Regular>
                        </Col>
                        <Col>
                            <Body3Regular className=" text-LAVEL_1">성별</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{gender}</Body2Regular>
                        </Col>
                    </Row>
                    {/* 키, 시작체중*/}
                    <Row className="mb-[40px]">
                        <Col className="mr-[80px]">
                            <Body3Regular className=" text-LAVEL_1">키</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{height}</Body2Regular>
                        </Col>
                        <Col>
                            <Body3Regular className=" text-LAVEL_1">시작 체중</Body3Regular>
                            <Body2Regular className=" text-LAVEL_4">{weight}</Body2Regular>
                        </Col>
                    </Row>
                    {/* 집 주소 */}
                    <Col className="mb-[40px]">
                        <Body3Regular className=" text-LAVEL_1">집 주소</Body3Regular>
                        <Body2Regular className=" text-LAVEL_4">{address}</Body2Regular>
                    </Col>
                    {/* 메모 */}
                    <Col className="mb-[40px] w-[786px] h-[106px] ">
                        <Body3Regular className="text-LAVEL_1">메모</Body3Regular>
                        <Body2Regular className="text-LAVEL_4 w-full break-all h-full line-clamp-3 text-ellipsis">
                            {memo}
                        </Body2Regular>
                    </Col>
                </Col>
            </Row>
            <Row className="flex flex-row w-[1192px] justify-between items-center">
                <TrashButton text={'회원 삭제'} onClick={onClickDeleteMember} />
                <Row>
                    {!(memberType === 'temp') && (
                        <NewWhiteRoundButton
                            text="수업 피드 바로가기"
                            onClick={onClickMoveToFeed}
                            width={157}
                            height={36}
                            className="mr-[16px]"
                            fontType={'Body3Regular'}
                        />
                    )}
                    <NewColorRoundButton
                        text="프로필 편집"
                        onClick={onClickEditProfile}
                        bgColor={'primary'}
                        textColor={'white'}
                        width={126}
                        height={36}
                        fontType={'Body3Regular'}
                    />
                </Row>
            </Row>
        </div>
    );
};

export default MemberProfileComponents;
