import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import dayjs from 'dayjs';
import WeeklyTimeCell from './WeeklyTimeCell';
import WeeklyScheduleCell from './WeeklyScheduleCell';
import ScheduleBlock from './BlockItem/ScheduleBlock';
import EtcScheduleBlock from './BlockItem/EtcScheduleBlock';
import GroupScheduleBlock from './BlockItem/GroupScheduleBlock';
import RequestBlock from './BlockItem/RequestBlock';

const WeekScheduleTable = ({
    weeklyScheduleList,
    weeklyGridData,
    lengthArr,
    selectedDay,
    scrollY,
    onClickSchedule,
    onClickRequest,
    onClickEtcOffSchedule,
    onClickApproveScheduleRequest,
    onClickRejectScheduleRequest,
}: any) => {
    return (
        <Col
            style={{
                width: '100%',
                marginTop: 100,
                backgroundColor: colors.WHITE_200,
                position: 'relative',
            }}
        >
            {weeklyGridData &&
                weeklyGridData.map((row: any, rowIndex: number) => {
                    return (
                        <>
                            <Row key={rowIndex + 'weekGridData'}>
                                {row.map((cell: any, cellIndex: number) => {
                                    // row에서 첫번째 열일 때
                                    if (cellIndex === 0) {
                                        return (
                                            // 주간 달력 좌측 시간 표시
                                            <WeeklyTimeCell
                                                key={cell.id}
                                                lengthArr={lengthArr}
                                                rowIndex={rowIndex}
                                                cell={cell}
                                            />
                                        );
                                    }
                                    // row에서 날짜 밑에있는 열들일때
                                    else {
                                        const cellSchedules = cell;
                                        // startTime을 기준으로 객체들을 묶어서 Map을 만듬.
                                        const cellSchedulesWithDuoMap = cellSchedules.reduce((acc: any, obj: any) => {
                                            const key = obj.coachId + '-' + obj.type + obj.startTime;

                                            if (!acc.has(key)) {
                                                acc.set(key, []);
                                            }
                                            acc.get(key).push(obj);

                                            return acc;
                                        }, new Map());

                                        // 중복되지 않은 요소들과 함께 배열로 만듬.
                                        const cellSchedulesWithDuo = Array.from(cellSchedulesWithDuoMap.values());

                                        return (
                                            // 주간 달력 표 내용

                                            // 표 레이아웃 그리기
                                            <Col
                                                style={{
                                                    width: '13.28%',
                                                    height: lengthArr
                                                        ? Math.max(...lengthArr[rowIndex]) === 0
                                                            ? 98
                                                            : (Math.max(...lengthArr[rowIndex]) - 1) * 98 + 98
                                                        : 98,
                                                    boxShadow: cellIndex === 0 ? '' : '0 0 0 0.1px black',
                                                    alignItems: 'center',
                                                    paddingTop: 4,
                                                    // paddingBottom: 4,
                                                }}
                                            >
                                                {cellSchedulesWithDuo &&
                                                    cellSchedulesWithDuo.map((childArr: any, childIndex: number) => {
                                                        const childItem = childArr[0];

                                                        return childItem.type === 'schedule' ? (
                                                            childArr.length === 1 ? (
                                                                <ScheduleBlock
                                                                    key={childIndex + 'weeklySchedule' + rowIndex}
                                                                    cellIndex={cellIndex}
                                                                    childItem={childItem}
                                                                    onClickSchedule={onClickSchedule}
                                                                />
                                                            ) : (
                                                                <GroupScheduleBlock
                                                                    key={childIndex + 'groupScheduleBox'}
                                                                    cellIndex={cellIndex}
                                                                    childArr={childArr}
                                                                    onClickSchedule={onClickSchedule}
                                                                />
                                                            )
                                                        ) : childItem.type === 'onetimeSchedule' ? (
                                                            <EtcScheduleBlock
                                                                key={childIndex + 'weeklyEtcSchedule' + rowIndex}
                                                                cellIndex={cellIndex}
                                                                childItem={childItem}
                                                                onClickEtcOffSchedule={onClickEtcOffSchedule}
                                                            />
                                                        ) : childItem.type === 'request' ? (
                                                            <RequestBlock
                                                                key={childIndex + 'weeklyEtcSchedule' + rowIndex}
                                                                cellIndex={cellIndex}
                                                                childItem={childItem}
                                                                onClickRequest={onClickRequest}
                                                                onClickApproveScheduleRequest={
                                                                    onClickApproveScheduleRequest
                                                                }
                                                                onClickRejectScheduleRequest={
                                                                    onClickRejectScheduleRequest
                                                                }
                                                            />
                                                        ) : null;
                                                    })}
                                            </Col>
                                        );
                                    }
                                })}
                            </Row>
                        </>
                    );
                })}
        </Col>
    );
};

export default WeekScheduleTable;
