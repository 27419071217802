import React from 'react';
import { Col, Row } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { CaptionRegular, Subtitle1Bold } from '../../../lib/font';
import dayjs from 'dayjs';

const WeekList = ({ scrollY, weeklyScheduleList }: any) => {
    return (
        <Row
            style={{
                width: '100%',
                height: 90,
                marginTop: scrollY ? 0 : 24,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #A6BFEF',

                position: 'fixed',
                top: 104,
                // transition: 'width 0.5s ease',
                left: 0,
                zIndex: 3,
            }}
        >
            <Row
                style={{
                    width: '7.5%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            />

            {weeklyScheduleList &&
                weeklyScheduleList.map((item: any, index: number) => {
                    const isToday =
                        dayjs(item.dateFormat).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? true : false;

                    return (
                        <Col
                            style={{
                                width: '13.28%',
                                height: 90,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Subtitle1Bold>{item.day.replace('일', '')}</Subtitle1Bold>
                            <Row
                                style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 12,
                                    backgroundColor: isToday ? colors.BLUE_500 : colors.WHITE_50,
                                    color: isToday ? colors.WHITE_50 : colors.LAVEL_2,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CaptionRegular>{item.daysOfWeek}</CaptionRegular>
                            </Row>
                        </Col>
                    );
                })}
        </Row>
    );
};

export default WeekList;
