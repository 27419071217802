import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../../components/modal/Modal';
import Table from '../../components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import { colors } from '../../lib/colors';
import { Col, getItemWithExpiration, isEmptyString, Row } from '../../lib/utils';
import SvgIcon from '../../components/SvgIcon';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import useSingleQuery from '../../hook/useSingleQuery';
import { fetchSalesCommissionQuery, SalesCommission } from '../../services/useSalesCommissionQueryService';
import useSalesCommissionMutationService from '../../services/useSalesCommissionMutationService';
import { useQueryClient } from '@tanstack/react-query';

const columnHelper = createColumnHelper<SalesCommission>();

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    date: string;
};

const ModalCommission = ({ setIsVisible, date: _date }: Props) => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const queryClient = useQueryClient();

    const tableRef = useRef<any>(null);
    const [date, setDate] = useState(_date);

    // 버튼 비활성
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { patchSalesCommissionMutation } = useSalesCommissionMutationService({
        onPatchSalesCommissionSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['getCenterSalesSettlementCalendarQuery'],
            });
            queryClient.invalidateQueries({
                queryKey: ['getClassFeeSettlement'],
            });
            queryClient.invalidateQueries({
                queryKey: ['getClassFeeSettlementForMembershipQuery'],
            });
            setIsVisible(false);
        },
    });

    //fetchSalesCommissionQuery
    const { refinedResult: singleResult, isLoading } = useSingleQuery({
        fetchFn: fetchSalesCommissionQuery,
        params: {
            year: dayjs(date).year(),
            month: dayjs(date).month() + 1,
            centerId: authObject?.centerId,
            accessToken: authorization,
        },
        enabled: !!authObject.centerId,
        suspense: false,
        cacheTime: 0,
    });
    const _data = singleResult?.data as SalesCommission[];

    // singleResult 데이터가 로드될 때 업데이트
    useEffect(() => {
        if (_data) {
            setData(_data);
        }
    }, [_data]);

    // 데이터
    const [data, setData] = useState<SalesCommission[]>(_data);
    // 데이터 컬럼 설정
    const [columnVisibility, setColumnVisibility] = useState<any>({
        reRegisterCommission: false,
        noshowCommission: false,
    });

    const columns = useMemo(() => {
        return [
            columnHelper.accessor('coachName', {
                id: 'coachName',
                header: '강사',
                cell: (info) => info.getValue(),
                size: 300,
                enableSorting: false,
            }),
            columnHelper.accessor('commissionType', {
                id: 'commissionType',
                header: '정률제/정액제 선택',
                cell: (info) => {
                    const value = info?.getValue() === null ? 'percentage' : info?.getValue();
                    return (
                        <div className="flex items-center justify-center">
                            <ToggleSwitch
                                value={value === 'percentage' ? 'percentage' : 'fixed'}
                                onToggle={(value) =>
                                    setData((prev) => {
                                        const newData = cloneDeep(prev);
                                        newData[info.row.index].commissionType =
                                            value === 'percentage' ? 'percentage' : 'fixed';
                                        newData[info.row.index].scheduleCommission = '0';
                                        newData[info.row.index].reRegisterCommission = '0';
                                        newData[info.row.index].noshowCommission = '0';
                                        return newData;
                                    })
                                }
                            />
                        </div>
                    );
                },
                size: 300,
                enableSorting: false,
            }),
            // 신규 커미션
            columnHelper.accessor('scheduleCommission', {
                id: 'scheduleCommission',

                header: !!columnVisibility['reRegisterCommission'] ? '신규' : '수업 정산액',
                // header: '신규',
                cell: (info) => {
                    // commissionType 이 null 인경우 '' 으로 변경
                    const initialValue = info.row.original.commissionType === null ? '' : info.getValue();
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [value, setValue] = useState(initialValue);

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        setValue(initialValue);
                    }, [initialValue]);

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    // useEffect(() => {
                    //     setValue(initialValue);
                    // }, [info.row.original.commissionType]);

                    return (
                        <TextInput
                            value={value}
                            onChange={(value) => setValue(value as any)}
                            // unit={selected === 'Percentage' ? '%' : '원'}
                            unit={
                                info.row.original.commissionType === null
                                    ? '%'
                                    : info.row.original.commissionType === 'percentage'
                                    ? '%'
                                    : '원'
                            }
                            width={80}
                            type={info.row.original.commissionType}
                            onBlur={() =>
                                setData((prev) => {
                                    const newData = [...prev];
                                    newData[info.row.index].scheduleCommission = value;
                                    return newData;
                                })
                            }
                        />
                    );
                },
                size: 300,
                enableSorting: false,
            }),
            // 재결제 커미션
            columnHelper.accessor('reRegisterCommission', {
                id: 'reRegisterCommission',
                header: '재결제',
                cell: (info) => {
                    const initialValue = info.getValue();
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [value, setValue] = useState(initialValue);

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        setValue(initialValue);
                    }, [initialValue]);

                    return (
                        <TextInput
                            value={value ?? ''}
                            onChange={(value) => setValue(value as any)}
                            // unit={selected === 'Percentage' ? '%' : '원'}
                            unit={info.row.original.commissionType === 'percentage' ? '%' : '원'}
                            width={80}
                            type={info.row.original.commissionType}
                            onBlur={() =>
                                setData((prev) => {
                                    const newData = [...prev];
                                    newData[info.row.index].reRegisterCommission = value;
                                    return newData;
                                })
                            }
                        />
                    );
                },
                size: 300,
                enableSorting: false,
            }),
            // 노쇼 커미션
            columnHelper.accessor('noshowCommission', {
                id: 'noshowCommission',
                header: '노쇼',
                cell: (info) => {
                    const initialValue = info.getValue();
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [value, setValue] = useState(initialValue);

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        setValue(initialValue);
                    }, [initialValue]);

                    return (
                        <TextInput
                            value={value as any}
                            onChange={(value) => setValue(value as any)}
                            // unit={selected === 'Percentage' ? '%' : '원'}
                            unit={info.row.original.commissionType === 'percentage' ? '%' : '원'}
                            width={80}
                            type={info.row.original.commissionType}
                            onBlur={() =>
                                setData((prev) => {
                                    const newData = [...prev];
                                    newData[info.row.index].noshowCommission = value;
                                    return newData;
                                })
                            }
                        />
                    );
                },
                size: 300,
                enableSorting: false,
            }),
        ];
    }, [columnVisibility]);

    // 처음 데이터에서 재결제 데이터가 하나라도 있으면 columnVisibility['reRegisterCommission'] = true
    useEffect(() => {
        if (_data) {
            const isReRegisterCommission = _data?.some((item) => !isEmptyString(item.reRegisterCommission));
            const isNoshowCommission = _data?.some((item) => !isEmptyString(item.noshowCommission));
            setColumnVisibility({
                reRegisterCommission: isReRegisterCommission,
                noshowCommission: isNoshowCommission,
            });
        }
    }, [_data]);

    const toggleColumnVisibility = (columnId: string) => {
        setColumnVisibility((prev: any) => {
            // 신규/재결제 별도 설정 버튼 true -> false 할때 재결제 컬럼 값들 모두 초기화
            if (columnId === 'reRegisterCommission' && prev['reRegisterCommission']) {
                setData((prev) => {
                    const newData = cloneDeep(prev);
                    newData.forEach((item) => {
                        item.reRegisterCommission = undefined;
                    });
                    return newData;
                });
            }
            if (columnId === 'noshowCommission' && prev['noshowCommission']) {
                setData((prev) => {
                    const newData = cloneDeep(prev);
                    newData.forEach((item) => {
                        item.noshowCommission = undefined;
                    });
                    return newData;
                });
            }
            return {
                ...prev,
                [columnId]: !prev[columnId],
            };
        });
    };

    // 렌더링 시 배열로 변환
    const visibleColumnsArray = Object.entries(columnVisibility).map(([id, visible]) => ({
        id,
        visible,
    }));
    useEffect(() => {
        if (!_data) return;
        let isDisabled = false;

        for (let i = 0; i < data.length; i++) {
            const row = data[i];

            if (columnVisibility['reRegisterCommission'] && columnVisibility['noshowCommission']) {
                if (
                    !isEmptyString(row.scheduleCommission) &&
                    !isEmptyString(row.reRegisterCommission) &&
                    !isEmptyString(row.noshowCommission)
                ) {
                    isDisabled = false;
                } else {
                    isDisabled = true;
                    break;
                }
            } else if (columnVisibility['reRegisterCommission']) {
                if (!isEmptyString(row.scheduleCommission) && !isEmptyString(row.reRegisterCommission)) {
                    isDisabled = false;
                } else {
                    isDisabled = true;
                    break;
                }
            } else if (columnVisibility['noshowCommission']) {
                if (!isEmptyString(row.scheduleCommission) && !isEmptyString(row.noshowCommission)) {
                    isDisabled = false;
                } else {
                    isDisabled = true;
                    break;
                }
            } else {
                if (!isEmptyString(row?.scheduleCommission)) {
                    isDisabled = false;
                } else {
                    isDisabled = true;
                    break;
                }
            }
        }

        setIsDisabled(isDisabled);
    }, [data, columnVisibility]);

    return (
        // <Modal whiteViewWidth={1000} setIsVisible={setIsVisible} isCloseOutside borderRadius={40} modalAlign="start">
        <Modal
            setIsVisible={setIsVisible}
            borderRadius={40}
            isCloseOutside
            modalAlign="start"
            mt={80}
            heightFull={false}
            isCloseButton
        >
            <div className="flex flex-col w-[920px] h-full  px-[35px] py-[36px] bg-white rounded-[40px]">
                <Row className=" items-center mb-[32px]">
                    <MediumText fontSize={38} lineHeight={55} color="#3D4244" className="mr-[12px]">
                        {dayjs(date).format('MM월')}
                    </MediumText>
                    <div
                        className="flex cursor-pointer bg-[#F4F5F7] w-[35px] h-[35px] justify-center items-center rounded-[4px] mr-[5px]"
                        onClick={() => setDate(dayjs(date).subtract(1, 'month').toISOString())}
                    >
                        <SvgIcon
                            name={'SvgLeftStrokeArrow'}
                            size={19}
                            fill={colors.trans}
                            stroke={'#70747C'}
                            strokeWidth={1.2}
                        />
                    </div>

                    <div
                        className="flex cursor-pointer bg-[#F4F5F7] w-[35px] h-[35px] justify-center items-center rounded-[4px] mr-[27px]"
                        onClick={() => setDate(dayjs(date).add(1, 'month').toISOString())}
                    >
                        <SvgIcon
                            name={'SvgRightStrokeArrow'}
                            size={19}
                            fill={colors.trans}
                            stroke={'#70747C'}
                            strokeWidth={1.2}
                        />
                    </div>
                    <MediumText fontSize={25} lineHeight={38} color="#3D4244" className="mr-[50px]">
                        월별 정산액 비율 설정
                    </MediumText>
                    <Row>
                        {
                            // coach, settlementType 제외
                            visibleColumnsArray.map((column: any, index: number) => {
                                if (
                                    column.id === 'coach' ||
                                    column.id === 'commissionType' ||
                                    column.id === 'newCommissionPercentage'
                                ) {
                                    return;
                                }
                                const text =
                                    column.id === 'reRegisterCommission'
                                        ? '신규/재결제 별도 설정'
                                        : column.id === 'noshowCommission'
                                        ? '노쇼 설정'
                                        : column.id;
                                return (
                                    <Row
                                        key={column.id}
                                        className="px-1 items-center"
                                        onClick={() => toggleColumnVisibility(column.id)}
                                    >
                                        <label
                                            className={`relative inline-flex items-center cursor-pointer ${
                                                index === 0 ? 'mr-[24px]' : ''
                                            }`}
                                        >
                                            <div
                                                className={`mr-[8px] border-[1px] border-primary border-solid rounded-md flex items-center justify-center ${
                                                    columnVisibility[column.id] ? 'bg-primary' : 'bg-white'
                                                }`}
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                            >
                                                {columnVisibility[column.id] ? (
                                                    <svg
                                                        className=" text-white"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            d="M5 11L10.6667 16.6666L20 6.66663"
                                                            stroke="white"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <div className="w-[15px] h-[15px]" />
                                                )}
                                            </div>
                                            <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                                {text}
                                            </RegularText>
                                        </label>
                                    </Row>
                                );
                            })
                        }
                    </Row>
                </Row>
                <Col
                    className="flex flex-col h-[480px] overflow-auto
                scrollbar-thumb-[#B4B4B6] scrollbar-track-gray-200 scrollbar scrollbar-w-[10px]  scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-h-[10px] 
                "
                >
                    {!isLoading && data && data?.length > 0 && (
                        <Table
                            ref={tableRef}
                            data={data}
                            columns={columns}
                            width={'100%'}
                            height={403}
                            isPagination={false}
                            isColumnVisibility
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                        />
                    )}
                </Col>
                <Row className="  self-end relative">
                    {isDisabled && (
                        <Col className="flex absolute  top-[-60px] ">
                            <Col className="py-[11px] px-[21px]  bg-[#36393E] rounded-[9px] cursor-pointer">
                                <RegularText fontSize={14} lineHeight={24} color="white" letterSpacing={-0.32}>
                                    모든 강사의 정산액 비율을 입력해주세요!
                                </RegularText>
                            </Col>
                            <div className=" mr-[30px] bg-[#00ff0000] border-r-[#36393E] self-end border-l-trans w-[10px] h-[10px] border-solid border-l-[30px] border-b-[10px] border-t-[0px] border-r-[10px] border-b-[#00ff0000]  border-l-transparent  border-t-[#00ff0000]  " />
                        </Col>
                    )}
                    <Col
                        className=" items-center justify-center w-[150px] h-[48px] bg-[#F4F5F7] rounded-[9px] mr-[13px] cursor-pointer"
                        onClick={() => setIsVisible(false)}
                    >
                        <RegularText fontSize={20} lineHeight={24} color="#3D4244">
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className={` items-center justify-center w-[150px] h-[48px]  rounded-[9px] bg-primary ${
                            isDisabled ? ' bg-opacity-[30%] cursor-not-allowed ' : 'cursor-pointer '
                        }
                         
                        `}
                        onClick={() => {
                            if (isDisabled) {
                                return;
                            }
                            // comissionType 이 null 인경우 'percentage' 으로 변경
                            const newData = data.map((item) => {
                                item.commissionType = item.commissionType ?? 'percentage';
                                return item;
                            });

                            patchSalesCommissionMutation.mutate({
                                year: dayjs(date).year().toString(),
                                month: (dayjs(date).month() + 1).toString(),
                                salesCommissionArray: newData,
                            });
                        }}
                    >
                        <RegularText fontSize={20} lineHeight={24} color="white">
                            적용하기
                        </RegularText>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default ModalCommission;

type ToggleProps = {
    value: 'percentage' | 'fixed';
    onToggle: (value: 'percentage' | 'fixed') => void;
};

export const ToggleSwitch: React.FC<ToggleProps> = ({ value, onToggle }) => {
    const [selected, setSelected] = useState<'percentage' | 'fixed'>(value);

    const handleToggle = (value: 'percentage' | 'fixed') => {
        setSelected(value);
        onToggle(value);
    };

    useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <div className="relative flex items-center  rounded-full w-[120px] h-[36px] bg-[#F4F5F7] overflow-hidden border border-solid border-[#E2E2E2]">
            {/* Animated slider */}
            <div
                className={`absolute w-[50%] h-[28px] bg-white rounded-full border border-solid border-[#E2E2E2] transition-transform duration-300 ease-in-out ${
                    selected === 'percentage' ? 'translate-x-[4px]' : 'translate-x-[56px]'
                }`}
            ></div>

            {/* percentage button */}
            <button
                className={`relative flex-1 text-center text-sm font-medium z-10 transition-colors duration-300 ${
                    selected === 'percentage' ? 'text-blue-500' : 'text-gray-500'
                }`}
                onClick={() => handleToggle('percentage')}
            >
                <MediumText
                    fontSize={16}
                    lineHeight={24}
                    color={selected === 'percentage' ? colors.primary : '#3D4244'}
                >
                    %
                </MediumText>
            </button>

            {/* Flat button */}
            <button
                className={`relative flex-1 text-center text-sm font-medium z-10 transition-colors duration-300 ${
                    selected === 'fixed' ? 'text-gray-700' : 'text-gray-500'
                }`}
                onClick={() => handleToggle('fixed')}
            >
                <MediumText fontSize={16} lineHeight={24} color={selected === 'fixed' ? colors.primary : '#3D4244'}>
                    원
                </MediumText>
            </button>
        </div>
    );
};

type TextInputProps = {
    value: string | number;
    onChange: (value: string | number) => void;
    unit?: string; // 단위 (예: %, 원)
    placeholder?: string; // 플레이스홀더 텍스트
    width?: number; // 입력 필드 너비
    disabled?: boolean; // 비활성화 여부
    type: 'fixed' | 'percentage';
    onBlur?: () => void;
};

const TextInput: React.FC<TextInputProps> = ({
    value,
    onChange,
    unit = '',
    placeholder = '',
    width = 116,
    disabled = false,
    type,
    onBlur,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    // 포커스
    const [isFocused, setIsFocused] = useState(false);
    //
    return (
        <div
            className={`flex items-center border  border-solid h-[46px] rounded-[10px] bg-white px-[18px] cursor-text hover:border-primary transition-colors duration-300 ease-in-out ${
                disabled ? 'bg-gray-100 text-gray-400' : 'bg-white'
            } ${isFocused ? 'border-primary' : 'border-[#D9D9D9B2]'}`}
            onClick={() => {
                inputRef.current?.focus();
            }}
        >
            <input
                ref={inputRef}
                type="text"
                value={value ?? ''}
                onChange={(e) => {
                    // 숫자입력시 0으로 시작하는 경우 0 제거
                    if (e.target.value.length > 1 && e.target.value[0] === '0') {
                        onChange(e.target.value.slice(1));
                        return;
                    }

                    // type === percentage 일때 100 이상 입력 방지
                    if (type === 'percentage' && Number(e.target.value) > 100) {
                        return;
                    }

                    // setText(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={placeholder}
                className={`flex flex-grow outline-none w-[80px] ${disabled ? 'cursor-not-allowed' : ''}`}
                // onFocus={() => setIsFocused(true)}
                // onBlur={() => {
                //     // onChange(text);
                //     setIsFocused(false);
                // }}
                onBlur={onBlur}
                disabled={disabled}
                maxLength={type === 'percentage' ? 3 : 7}
                // 숫자만
            />
            {unit && <DemiLightText className="flex">{unit}</DemiLightText>}
        </div>
    );
};
