import React, { useState } from 'react';
import { colors } from '../../../lib/colors';
import { Col, Row } from '../../../lib/utils';
import EmptyHoverBox from './EmptyHoverBox';
import dayjs from 'dayjs';

const ScheduleTableLayout = ({ dailyCurrentData, gridData, scrollY, onClickHover, selectedDay }: any) => {
    return (
        <Col
            style={{
                width: '100%',
                marginTop: 100,
                backgroundColor: colors.WHITE_200,
                position: 'relative',
            }}
        >
            {gridData &&
                gridData.map((row: any, rowIndex: number) => {
                    return (
                        <Row key={rowIndex}>
                            {row.map((cell: any, cellIndex: any) => {
                                return cellIndex === 0 ? (
                                    <Row
                                        key={cell.id}
                                        style={{
                                            width: '7.5%',
                                            height: 120,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: cellIndex === 0 ? '' : '0 0 0 0.1px black',
                                            background: cellIndex === 0 ? colors.WHITE_50 : colors.WHITE_200,
                                        }}
                                    >
                                        <Row
                                            style={{
                                                width: '74%',
                                                height: 32,
                                                border:
                                                    cell.content === dayjs().format('A hh시')
                                                        ? '1px  solid #1A305D'
                                                        : '',
                                                borderRadius: 24,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: 12,
                                                fontWeight: cell.content === dayjs().format('A hh시') ? 700 : 400,
                                                color:
                                                    cell.content === dayjs().format('A hh시')
                                                        ? colors.LAVEL_4
                                                        : colors.LAVEL_2,
                                            }}
                                        >
                                            <span>
                                                {cell.content === '오후 12시'
                                                    ? '정오 12시'
                                                    : cell.content === '오전 12시'
                                                    ? '자정 12시'
                                                    : cell.content}
                                            </span>
                                        </Row>
                                    </Row>
                                ) : (
                                    <EmptyHoverBox
                                        key={cell.id}
                                        rowIndex={rowIndex}
                                        cellIndex={cellIndex}
                                        gridData={gridData}
                                        dailyCurrentData={dailyCurrentData}
                                        onClickHover={onClickHover}
                                        selectedDay={selectedDay}
                                    />
                                );
                            })}
                        </Row>
                    );
                })}
        </Col>
    );
};

export default ScheduleTableLayout;
