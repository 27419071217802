import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import { Col, Row } from '../../lib/utils';
import { PaymentHistoryType } from '../../services/useMembershipQueryService';
import NewCalendar from '../Calendar/NewCalendar';
import dayjs from 'dayjs';
import { BoldText, DemiLightText, MediumText, RegularText } from '../../lib/Text';
import { SalesStatus } from '../../services/useMembershipPaymentsQueryService';
import useMembershipPaymentsMutationService from '../../services/useMembershipPaymentsMutationService';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    paymentHistory?: PaymentHistoryType;
    createAlert?: any;
};

function ModalPaymentHistoryUpdate({ paymentHistory, setIsVisible, createAlert }: Props) {
    // const title = type === 'refund' ? '수강권 환불' : '수강권 업그레이드';
    //'COMPLETED' | 'UPGRADED' | 'PARTIAL_REFUND' | 'FULL_REFUND' | 'PARTIAL_PAYMENT'
    const queryClient = useQueryClient();
    const { patchMembershipPaymentMutation, deleteMembershipPaymentMutation } = useMembershipPaymentsMutationService({
        onPatchMembershipPaymentSuccess() {
            queryClient.invalidateQueries({ queryKey: ['getMembershipForMemberQuery'] });
            // createAlert('입력 오류', '추가 가능한 시간을 초과하였습니다', '확인', () => {});
            createAlert('수정 완료', '수정이 완료되었습니다', '확인', () => {});

            setIsVisible(false);
        },
        onDeleteMembershipPaymentSuccess() {
            queryClient.invalidateQueries({ queryKey: ['getMembershipForMemberQuery'] });
            createAlert('삭제 완료', '삭제가 완료되었습니다', '확인', () => {});
            setIsVisible(false);
        },
    });
    const type = paymentHistory?.saleStatus as SalesStatus;
    const membershipPaymentId = paymentHistory?._id as string;
    const title =
        type === 'FULL_REFUND'
            ? '전체 환불 수정'
            : type === 'UPGRADED'
            ? '업그레이드 금액 수정'
            : type === 'PARTIAL_REFUND'
            ? '환불 금액 수정'
            : type === 'PARTIAL_PAYMENT'
            ? '부분 결제 금액 수정'
            : '완료 결제 수정';

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    // 환불결제일자 div ref
    const divRef = React.useRef<HTMLDivElement>(null);
    // div 위치
    const [divPosition, setDivPosition] = useState({ top: 0, left: 0 });
    const [date, setDate] = useState(paymentHistory?.paymentDate || dayjs().toISOString());
    // 부분활불일땐 cancelAmount
    const [price, setPrice] = useState(
        (type === 'PARTIAL_REFUND' ? paymentHistory?.cancelAmount : paymentHistory?.paymentAmount) || 0
    );
    // 회차
    const [count, setCount] = useState(paymentHistory?.totalSessionOffset || 0);

    const priceTitle =
        type === 'FULL_REFUND'
            ? '전체 환불 금액(취소액)'
            : type === 'UPGRADED'
            ? '업그레이드 결제 금액(추가결제)'
            : type === 'PARTIAL_REFUND'
            ? '부분 환불 금액(취소액)'
            : type === 'PARTIAL_PAYMENT'
            ? '부분 결제 금액'
            : '완료 결제 금액';

    const dateTitle =
        type === 'FULL_REFUND'
            ? '전체 환불 일시'
            : type === 'UPGRADED'
            ? '업그레이드 결제 일시'
            : type === 'PARTIAL_REFUND'
            ? '부분 환불 일시'
            : type === 'PARTIAL_PAYMENT'
            ? '부분 결제 일시'
            : '완료 결제 일시';

    const countTitle =
        type === 'FULL_REFUND'
            ? '전체 환불할 회차 수'
            : type === 'UPGRADED'
            ? '업그레이드한 회차 수(추가한 회차 수)'
            : type === 'PARTIAL_REFUND'
            ? '부분 환불한 회차 수'
            : type === 'PARTIAL_PAYMENT'
            ? '부분 결제 회차'
            : '완료 결제 회차';

    useEffect(() => {
        if (divRef.current) {
            const rect = divRef.current.getBoundingClientRect();
            setDivPosition({ top: rect.top + rect.height, left: rect.left });
        }
    }, [divRef]);

    // 엔터씨 수정하기
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            patchMembershipPaymentMutation.mutate({
                type,
                amount: price,
                date,
                membershipPaymentId,
            });
        }
    };

    return (
        <Modal
            setIsVisible={setIsVisible}
            whiteViewWidth={500}
            borderRadius={25}
            isCloseOutside={false}
            isCloseButton
            modalCommpont={
                isCalendarOpen ? (
                    <NewCalendar
                        date={dayjs(date)}
                        selectedDay={dayjs(date)}
                        setIsCalendar={setIsCalendarOpen}
                        onClickDate={(day) => {
                            setDate(dayjs(day).toISOString());
                        }}
                        isAllDate={false}
                        top={divPosition.top}
                        left={divPosition.left + 16}
                        isNextMonth
                        isPrevMonth
                        titleFormat="YYYY년 MM월"
                    />
                ) : undefined
            }
            onClose={() => {
                setIsVisible(false);
            }}
        >
            <Col className="flex w-full h-full p-[35px]">
                <Row className="flex items-center mb-[23px]">
                    <MediumText fontSize={28} lineHeight={38} color="#3D4244" className="mr-[31px]">
                        {title}
                    </MediumText>
                </Row>
                <Col ref={divRef} className="flex flex-col mb-[33px]">
                    <RegularText fontSize={16} lineHeight={23} color="#505967" className="mb-[12px]">
                        {dateTitle}
                    </RegularText>
                    <Col
                        className={`flex  border  border-solid h-[67px] justify-center rounded-[10px] bg-white px-[18px] cursor-pointer hover:border-primary transition-colors duration-300 ease-in-out ${
                            isCalendarOpen ? 'border-primary' : 'border-[#D9D9D9B2]'
                        }`}
                        onClick={() => {
                            setIsCalendarOpen(true);
                        }}
                    >
                        <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                            {dayjs(date).format('YYYY-MM-DD')}
                        </RegularText>
                    </Col>
                </Col>
                <Col className="flex flex-col w-full  justify-between">
                    <Col className={`${type !== 'COMPLETED' ? 'mb-[33px]' : 'mb-[48px]'}`}>
                        <RegularText fontSize={16} lineHeight={23} color="#505967" className="mb-[12px]">
                            {priceTitle}
                        </RegularText>
                        <TextInput
                            value={price}
                            onChange={(value) => {
                                setPrice(value as number);
                            }}
                            unit="원"
                            placeholder="0"
                            width={116}
                            onKeyDown={handleKeyDown}
                        />
                    </Col>
                    {type !== 'COMPLETED' && type !== 'PARTIAL_PAYMENT' && (
                        <Col className="mb-[48px]">
                            <RegularText fontSize={16} lineHeight={23} color="#505967" className="mb-[12px]">
                                {countTitle}
                            </RegularText>
                            <TextInput
                                value={count}
                                onChange={(value) => {
                                    setCount(value as number);
                                }}
                                unit="회"
                                placeholder="0"
                                width={116}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                    )}

                    <Row className="self-end">
                        <Col
                            className="flex justify-center items-center  w-[110px] h-[48px]  bg-[#F2F4F6] rounded-[9px] cursor-pointer mr-[13px]"
                            onClick={() => {
                                deleteMembershipPaymentMutation.mutate({
                                    membershipPaymentId,
                                });
                            }}
                        >
                            <RegularText fontSize={16} lineHeight={20} color="#3D4244">
                                삭제하기
                            </RegularText>
                        </Col>
                        <Col
                            className="flex justify-center items-center  w-[110px] h-[48px]  bg-primary rounded-[9px] cursor-pointer"
                            onClick={() => {
                                const offset = type === 'COMPLETED' || type === 'PARTIAL_PAYMENT' ? undefined : count;

                                patchMembershipPaymentMutation.mutate({
                                    type,
                                    amount: price,
                                    date,
                                    offset,
                                    membershipPaymentId,
                                });
                            }}
                        >
                            <RegularText fontSize={16} lineHeight={20} color="white">
                                수정하기
                            </RegularText>
                        </Col>
                    </Row>
                </Col>
            </Col>
        </Modal>
    );
}

export default ModalPaymentHistoryUpdate;

type TextInputProps = {
    value: string | number;
    onChange: (value: string | number) => void;
    unit?: string; // 단위 (예: %, 원)
    placeholder?: string; // 플레이스홀더 텍스트
    width?: number; // 입력 필드 너비
    disabled?: boolean; // 비활성화 여부
    onBlur?: () => void;
    error?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};
const TextInput: React.FC<TextInputProps> = ({
    value,
    onChange,
    unit = '',
    placeholder = '',
    width = 116,
    disabled = false,
    onBlur,
    error = false,
    onKeyDown,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    // 포커스
    const [isFocused, setIsFocused] = useState(false);
    //
    return (
        <div
            className={`flex items-center border  border-solid h-[67px] rounded-[10px] bg-white px-[18px] cursor-text hover:border-primary transition-colors duration-300 ease-in-out ${
                disabled ? 'bg-gray-100 text-gray-400' : 'bg-white'
            } ${isFocused ? 'border-primary' : error ? 'border-red' : 'border-[#D9D9D9B2]'}`}
            onClick={() => {
                inputRef.current?.focus();
            }}
        >
            <input
                ref={inputRef}
                type="text"
                value={value}
                onChange={(e) => {
                    // 숫자입력시 0으로 시작하는 경우 0 제거
                    if (e.target.value.length > 1 && e.target.value[0] === '0') {
                        onChange(e.target.value.slice(1));
                        return;
                    }

                    // setText(e.target.value);
                    onChange(e.target.value);
                }}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                className={`flex flex-grow outline-none w-[80px] text-[16px] leading-[23px] text-[#3D4244]  ${
                    disabled ? 'cursor-not-allowed' : ''
                }`}
                // onFocus={() => setIsFocused(true)}
                // onBlur={() => {
                //     // onChange(text);
                //     setIsFocused(false);
                // }}
                onBlur={onBlur}
                disabled={disabled}

                // 숫자만
            />
            {unit && <DemiLightText className="flex">{unit}</DemiLightText>}
        </div>
    );
};
