import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, getItemWithExpiration, Row } from '../../../lib/utils';
import { Body2Bold, Body3Regular, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import { I_POPUP_EXIT, I_SCHEDULE_CIRCLEX } from '../../../types/images';
import dayjs from 'dayjs';
import NormalToggleButton from '../../ToggleButton/NormalToggleButton';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import ColorSquareButton from '../../Button/ColorSquareButton';
import {
    deleteScheduleAPI,
    modifyScheduleAttendance,
    updateScheduleSessionAPI,
    deleteRepeatScheduleAPI,
} from '../../../api/schedule';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ModalUpdateScheduleSession from './ModalUpdateScheduleSession';
import { I_DISABLED_SOLID_PLUS } from '../../../types/buttonImage';
import ModalNoShowAlert from '../ModalNoShowAlert';
import { useNavigate } from 'react-router-dom';
import Modal from '../../modal/Modal';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

const ModalInner = styled.div`
    background: #f5f9ff;
    width: 928px;
    height: 564px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0px #0000007a;

    background-color: #fdfeff;
    display: flex;
    flex-direction: column;
`;

const HoverImage = styled.img`
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    detailObject: any;
    createAlert: any;
    createTwoButtonAlert: any;
    calendarType: 'daily' | 'weekly';
    calendarSelectedDay?: string;
};

const ScheduleDetailModal = ({
    modalVisible,
    setModalVisible,
    detailObject,
    createAlert,
    createTwoButtonAlert,
    calendarType,
    calendarSelectedDay,
}: Props) => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const isGoingSchedule = dayjs().isAfter(detailObject?.startTime) && dayjs().isBefore(detailObject?.endTime);
    const [attendState, setAttendState] = useState<string>('ATTENDANCE');
    const [scheduleObject, setScheduleObject] = useState(detailObject);
    // 수업 일정 회차 정보 수정 모달
    const [isUpdateScheduleSessionVisible, setIsUpdateScheduleSessionVisible] = useState(false);
    // 노쇼 경고 모달
    const [isShowNoShowAlert, setIsShowNoShowAlert] = useState(false);
    // 수업 일정 회차
    const [currentSession, setCurrentSession] = useState<number>(detailObject?.currentSession);
    const isDeleted = detailObject.isDeleted;

    // 출석 노쇼 변환 API : (patch)
    const updateScheduleAttendance = useMutation({
        mutationFn: async () =>
            await modifyScheduleAttendance(
                scheduleObject?._id || scheduleObject?.id,
                attendState === 'ATTENDANCE' ? 'NOSHOW' : 'ATTENDANCE',
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                setAttendState(res.data.schedule.attendanceStatus);
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getMemberScheduleHistoryQuery'] });
                queryClient.invalidateQueries({ queryKey: ['getMembershipForMemberQuery'] });
            }
            if (res.status === 400) {
                createAlert('오류', '해당 시간에는 스케줄을 생성 할 수 없습니다', true);
            }

            if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert('중복', '해당 시간에 이미 스케줄이 등록되어있습니다!\n다시 한번 확인해주세요!', true);
            }
        },
        onError: () => {
            createAlert('오류', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
        },
    });

    // 스케줄 삭제 API : (delete)
    const deleteSchedule = useMutation({
        mutationFn: async () =>
            await deleteScheduleAPI(
                authObject?.centerId,
                scheduleObject?._id || scheduleObject?.id,
                scheduleObject?.coachId,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 204) {
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
                createAlert('성공', '일정이 삭제되었습니다!', false);
            } else {
                createAlert('오류', '오류가 발생하였습니다!\n다시 한번 삭제해주세요!', true);
            }
        },
        onError: () => {
            createAlert('', '오류가 발생하였습니다!\n다시 한번 삭제해주세요!', true);
        },
    });

    // 스케줄 반복 삭제 API : (delete)
    const deleteRepeatSchedule = useMutation({
        mutationFn: async () =>
            await deleteRepeatScheduleAPI(
                authObject?.centerId,
                scheduleObject?._id,
                scheduleObject?.scheduleBundleId,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 204) {
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
            } else {
                createAlert('오류', '오류가 발생하였습니다!\n다시 한번 삭제해주세요!', true);
            }
        },
        onError: () => {
            createAlert('', '오류가 발생하였습니다!\n다시 한번 삭제해주세요!', true);
        },
    });

    // 수강권 회차 수정 API : (patch)
    const updateScheduleSession = useMutation({
        mutationFn: async (value: number) =>
            await updateScheduleSessionAPI(
                scheduleObject?.coachId,
                scheduleObject?.memberId,
                scheduleObject?._id || scheduleObject?.id,
                value,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                setCurrentSession(res.data.currentSession);
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
            } else if (res.status === 210) {
                createAlert(
                    '수강권 회차 수정 실패',
                    `이미 ${scheduleObject.minSession}회 이상의 수업을 진행하셨습니다.\n수업 정보를 수정해주시거나 ${scheduleObject.minSession}회 이상의 값을 입력해주세요!`,
                    true
                );
            } else {
                createAlert('오류', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
            }
        },
        onError: () => {
            createAlert('', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
        },
    });

    // 출석 노쇼버튼 클릭이벤트
    const onClickAttendToggleButton = () => {
        // updateScheduleAttendance.mutate();
        if (attendState === 'ATTENDANCE') {
            setIsShowNoShowAlert(true);
        } else {
            updateScheduleAttendance.mutate();
        }
    };

    // 회차 수정 버튼 클릭이벤트
    const onClickUpdateScheduleButton = (value: number) => {
        updateScheduleSession.mutate(value);
    };

    // 일정 삭제 버튼 클릭이벤트
    const onClickDeleteButton = () => {
        deleteSchedule.mutate();
        setModalVisible(false);
    };

    // 일정 삭제 버튼 클릭이벤트
    const onClickRepeatDeleteButton = () => {
        deleteRepeatSchedule.mutate();
        setModalVisible(false);
    };

    useEffect(() => {
        if (scheduleObject) {
            setAttendState(scheduleObject?.attendanceStatus);
        }
    }, [scheduleObject]);

    useEffect(() => {
        if (modalVisible) {
            // 모달이 열릴 때 body의 스크롤을 막음
            document.body.style.overflow = 'hidden';
        } else {
            // 모달이 닫힐 때 body의 스크롤을 복원
            document.body.style.overflow = 'auto';
        }

        // 컴포넌트가 언마운트될 때 스크롤을 복원
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalVisible]);

    // 호버시
    const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.style.backgroundColor = '#F5F9FF';
        e.currentTarget.style.color = '#3D73DD';
    };

    // 디폴트 컬러
    const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.style.backgroundColor = '#FDFEFF';
        e.currentTarget.style.color = '#3869C9';
    };

    return (
        <Modal setIsVisible={setModalVisible} isCloseButton>
            <Row>
                <Subtitle1Bold style={{ color: colors.LAVEL_4, marginLeft: 48, marginTop: 36 }}>
                    {'수업 일정 자세히보기'}
                </Subtitle1Bold>

                <Row
                    style={{
                        width: 112,
                        height: 32,
                        borderRadius: 4,
                        border: '1px solid #648fe4',
                        marginTop: 38,
                        marginLeft: 16,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isGoingSchedule ? colors.BLUE_50 : '',
                    }}
                >
                    <Body3Regular style={{ color: colors.POINT_1 }}>
                        {isGoingSchedule
                            ? '수업 진행중'
                            : dayjs(scheduleObject?.endTime).isAfter(dayjs())
                            ? '수업 예정'
                            : '수업 완료'}
                    </Body3Regular>
                </Row>
            </Row>

            <Row style={{ marginTop: 48, alignItems: 'center' }}>
                <Col style={{ width: 310, height: 68, marginLeft: 72, justifyContent: 'space-between' }}>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>담당자</Body3Regular>
                    <Row
                        style={{
                            width: 310,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #ededed',
                            paddingLeft: 16,
                            backgroundColor: colors.WHITE_500,
                            alignItems: 'center',
                        }}
                    >
                        <Body3Regular
                            style={{ color: colors.LAVEL_1 }}
                        >{`${scheduleObject?.coachName} 강사`}</Body3Regular>
                    </Row>
                </Col>

                <Col style={{ width: 340, height: 68, marginLeft: 104, justifyContent: 'space-between' }}>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>날짜</Body3Regular>
                    <Row
                        style={{
                            width: 340,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #ededed',
                            paddingLeft: 16,
                            backgroundColor: colors.WHITE_500,
                            alignItems: 'center',
                        }}
                    >
                        <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(scheduleObject?.startTime).format(
                            'YYYY년 MM월 DD일 (ddd)'
                        )}`}</Body3Regular>
                    </Row>
                </Col>
            </Row>

            <Row style={{ marginTop: 48, alignItems: 'center' }}>
                <Col style={{ width: 310, height: 68, marginLeft: 72, justifyContent: 'space-between' }}>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>회원</Body3Regular>
                    <Row
                        style={{
                            width: 310,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #ededed',
                            paddingLeft: 16,
                            backgroundColor: colors.WHITE_500,
                            alignItems: 'center',
                        }}
                    >
                        <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${scheduleObject?.alias} 회원`}</Body3Regular>
                    </Row>
                </Col>

                <Col style={{ width: 340, height: 68, marginLeft: 104, justifyContent: 'space-between' }}>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>시간</Body3Regular>
                    <Row
                        style={{
                            width: 340,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #ededed',
                            paddingLeft: 16,
                            backgroundColor: colors.WHITE_500,
                            alignItems: 'center',
                        }}
                    >
                        <Body3Regular style={{ color: colors.LAVEL_1 }}>{`${dayjs(scheduleObject?.startTime).format(
                            'A hh시 mm분 ~ '
                        )}  ${dayjs(scheduleObject?.endTime).format('A hh시 mm분')}`}</Body3Regular>
                    </Row>
                </Col>
            </Row>

            <Row style={{ marginLeft: 72 }}>
                <Row
                    style={{
                        width: 310,
                        height: 92,
                        borderRadius: 16,
                        marginTop: 16,
                        background: `${
                            scheduleObject?.membership?.status === 'active'
                                ? `linear-gradient(135deg, ${scheduleObject?.membership?.BGColor[0]} 0%, ${scheduleObject?.membership?.BGColor[1]} 100%)`
                                : colors.GREY_200
                        }`,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 18,
                        paddingBottom: 18,
                    }}
                >
                    <Body2Bold style={{ color: colors.LAVEL_0 }}>{scheduleObject?.membership?.name}</Body2Bold>
                </Row>

                <Col
                    style={{
                        width: 160,
                        height: 68,
                        marginLeft: 104,
                        marginTop: 48,
                        justifyContent: 'space-between',
                    }}
                >
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>회차 정보</Body3Regular>
                    <Row
                        style={{
                            width: 244,
                            height: 36,
                        }}
                    >
                        <Row
                            style={{
                                width: 160,
                                height: 36,
                                borderRadius: 36,
                                border: '1px solid #ededed',
                                paddingLeft: 16,
                                backgroundColor: colors.WHITE_500,
                                alignItems: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_1 }}>
                                {currentSession ? currentSession : '?'}/
                                {scheduleObject?.totalSession ? scheduleObject?.totalSession : '?'}회차
                            </Body3Regular>
                        </Row>
                        {!isDeleted && (
                            <button
                                style={{
                                    width: 72,
                                    height: 36,
                                    borderRadius: 48,
                                    border: '1px solid var(--PrimaryColor-Blue-Blue-500, #3D73DD)',
                                    backgroundColor: 'var(--PrimaryColor-White-White-050, #FDFEFF)',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 12,
                                }}
                                onClick={() => {
                                    setIsUpdateScheduleSessionVisible(true);
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                            >
                                <Body3Regular
                                    style={{
                                        color: colors.BLUE_500,
                                    }}
                                >
                                    수정
                                </Body3Regular>
                            </button>
                        )}
                    </Row>
                </Col>
            </Row>

            <Row style={{ alignItems: 'center', marginTop: 60, marginBottom: 24, marginRight: 36 }}>
                <Row style={{ alignItems: 'center', marginLeft: 72 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4, marginRight: 8 }}>{'출석'}</Body3Regular>
                    <NormalToggleButton
                        isActive={attendState === 'ATTENDANCE'}
                        callBack={onClickAttendToggleButton}
                        disableColor="#FF3E4A"
                    />
                    <Body3Regular style={{ color: colors.LAVEL_4, marginLeft: 8 }}>{'노쇼'}</Body3Regular>
                </Row>
                {/* width 335 */}
                {isDeleted ? (
                    <Row
                        style={{
                            width: 335,
                            marginLeft: 361,

                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row style={{ width: 155 }}>
                            <img src={I_SCHEDULE_CIRCLEX} style={{ width: 24, height: 24, marginRight: 4 }} />
                            <Body3Regular>삭제된 회원이에요!</Body3Regular>
                        </Row>
                        <WhiteSquareButton
                            text="일정 삭제"
                            size="middle"
                            callBack={() => {
                                return scheduleObject.scheduleBundleId === null
                                    ? createTwoButtonAlert(
                                          '수업을 삭제하시겠습니까?',
                                          `수업을 삭제하시면 회원님께 수업 취소 알림이 전달되며,\n해당 수강권 회차도 수정됩니다! `,
                                          true,
                                          '취소',
                                          '삭제',
                                          () => {},
                                          () => {
                                              onClickDeleteButton();
                                          }
                                      )
                                    : createTwoButtonAlert(
                                          '반복 수업 일정이에요!',
                                          `반복 수업으로 생성된 수업을 일괄 삭제하시겠습니까?\n수업을 삭제하시면 해당 수강권의 회차도 수정됩니다! `,
                                          true,
                                          '이번 수업만 삭제',
                                          '모두 삭제',
                                          () => {
                                              onClickDeleteButton();
                                          },
                                          () => {
                                              onClickRepeatDeleteButton();
                                          },
                                          true
                                      );
                            }}
                        />
                    </Row>
                ) : (
                    <Row
                        style={{
                            width: 312,
                            marginLeft: 360,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Row
                            style={{
                                width: 180, // 버튼 총 너비
                                marginLeft: -120,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                position: 'relative', // 이미지 배치를 위해
                            }}
                        >
                            <img
                                src={I_DISABLED_SOLID_PLUS}
                                style={{
                                    width: 18, // 아이콘 크기
                                    height: 18,
                                    marginRight: 10, // 텍스트와 아이콘 간 간격
                                }}
                                alt="plus icon"
                            />
                            <button
                                style={{
                                    border: 0,
                                    fontSize: 16,
                                    backgroundColor: 'transparent', // 버튼 배경 없애기
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate('/schedule/create', {
                                        state: {
                                            type: 'oneMoreSchedule',
                                            selectedSchedule: scheduleObject,
                                            calendarType,
                                            calendarSelectedDay,
                                        },
                                    });
                                }}
                            >
                                {'회원 추가하기'}
                            </button>
                        </Row>

                        <WhiteSquareButton
                            text="일정 삭제"
                            size="middle"
                            callBack={() => {
                                return scheduleObject.scheduleBundleId === null
                                    ? createTwoButtonAlert(
                                          '수업을 삭제하시겠습니까?',
                                          `수업을 삭제하시면 회원님께 수업 취소 알림이 전달되며,\n해당 수강권 회차도 수정됩니다! `,
                                          true,
                                          '취소',
                                          '삭제',
                                          () => {},
                                          () => {
                                              onClickDeleteButton();
                                          }
                                      )
                                    : createTwoButtonAlert(
                                          '반복 수업 일정이에요!',
                                          `반복 수업으로 생성된 수업을 일괄 삭제하시겠습니까?\n수업을 삭제하시면 해당 수강권의 회차도 수정됩니다! `,
                                          true,
                                          '이번 수업만 삭제',
                                          '모두 삭제',
                                          () => {
                                              onClickDeleteButton();
                                          },
                                          () => {
                                              onClickRepeatDeleteButton();
                                          },
                                          true
                                      );
                            }}
                        />
                        <ColorSquareButton
                            text="수정하기"
                            size="middle"
                            customStyle={{ marginLeft: 10 }}
                            callBack={() => {
                                navigate('/schedule/update', {
                                    state: {
                                        type: 'oneSchedule',
                                        scheduleObject: {
                                            _id: scheduleObject._id || scheduleObject.id,
                                            ...scheduleObject,
                                        },
                                        calendarType,
                                        calendarSelectedDay,
                                    },
                                });
                            }}
                        />
                    </Row>
                )}
            </Row>

            {isUpdateScheduleSessionVisible && (
                <ModalUpdateScheduleSession
                    onClickUpdateScheduleButton={onClickUpdateScheduleButton}
                    currentSession={currentSession}
                    totalSession={scheduleObject?.totalSession}
                    minSession={scheduleObject?.minSession}
                    setIsVisible={setIsUpdateScheduleSessionVisible}
                />
            )}
            {isShowNoShowAlert && (
                <ModalNoShowAlert
                    setIsVisible={setIsShowNoShowAlert}
                    onClickNoShow={() => {
                        updateScheduleAttendance.mutate();
                        setIsShowNoShowAlert(false);
                    }}
                />
            )}
        </Modal>
    );
};

export default ScheduleDetailModal;
