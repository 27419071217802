import axios, { AxiosResponse } from 'axios';
import { SalesStatus } from '../services/useMembershipPaymentsQueryService';
import { createDataObject } from '../lib/utils';

export /**
 * @method get
 * @brief 관리자의 매출정산 기능 매출 목록 조회
 *  https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1195/src/docs/MembershipPayment.md#%EB%9D%BC%ED%8F%AC%EB%A7%A4%EB%8B%88%EC%A0%80-%EB%A7%A4%EC%B6%9C%EC%A0%95%EC%82%B0-%EA%B4%80%EB%A0%A8-api%EB%93%A4
 */

const getSalesList = async ({
    startDate,
    endDate,
    userId,
    role,
    centerId,
    accessToken,
}: GetSalesListParams & { accessToken: string }): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipPayments/salesList/${centerId}?startDate=${startDate}&endDate=${endDate}&userId=${userId}&role=${role}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method post
 * @brief 관리자의 판매담당자 직접입력

 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1195/src/docs/MembershipPayment.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%ED%8C%90%EB%A7%A4%EB%8B%B4%EB%8B%B9%EC%9E%90-%EC%A7%81%EC%A0%91%EC%9E%85%EB%A0%A5
 */

const postSalesManager = async (membershipId: string, managerName: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/membershippayments/salesManagers/${membershipId}`,
            headers: { authorization: accessToken },
            data: {
                userName: managerName,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method get
 * @brief 관리자의 판매담당자 조회
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1195/src/docs/MembershipPayment.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%ED%8C%90%EB%A7%A4%EB%8B%B4%EB%8B%B9%EC%9E%90-%EC%A1%B0%ED%9A%8C
 */

const getSalesManagers = async (centerId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/membershippayments/salesManagers/${centerId}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 관리자의 수강권 환불
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1195/src/docs/MembershipPayment.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%EC%88%98%EA%B0%95%EA%B6%8C-%ED%99%98%EB%B6%88
 */

const patchMembershipRefund = async ({
    membershipId,
    session,
    amount,
    date,
    accessToken,
}: PatchMembershipRefundAndUpgradedParams): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/membershippayments/refund/${membershipId}`,
            headers: { authorization: accessToken },
            data: {
                session,
                amount,
                date,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method patch
 * @brief 관리자의 수강권 업그레이드
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1195/src/docs/MembershipPayment.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%EC%88%98%EA%B0%95%EA%B6%8C-%EC%97%85%EA%B7%B8%EB%A0%88%EC%9D%B4%EB%93%9C
 */

const patchMembershipUpgrade = async ({
    membershipId,
    session,
    amount,
    date,
    accessToken,
}: PatchMembershipRefundAndUpgradedParams): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/membershippayments/upgrade/${membershipId}`,
            headers: { authorization: accessToken },
            data: {
                session,
                amount,
                date,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method post
 * @brief 관리자의 수강권 분할 결제 처리
 * https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1204/src/docs/MembershipPayment.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%EC%88%98%EA%B0%95%EA%B6%8C-%EB%B6%84%ED%95%A0-%EA%B2%B0%EC%A0%9C-%EC%B2%98%EB%A6%AC
 */

const postSplitPayment = async ({ membershipId, amount, date, accessToken }: PostSplitPaymentParams): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/membershippayments/splitpay/${membershipId}`,
            headers: { authorization: accessToken },
            data: {
                amount,
                date,
            },
        });

        if (response.status === 201) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 *
 * @brief 관리자의 수강권 내역 수정
 * https://github.com/mustMoveProject/rappo-project/blob/develop/src/docs/MembershipPayment.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%EC%88%98%EA%B0%95%EA%B6%8C-%EB%82%B4%EC%97%AD-%EC%88%98%EC%A0%95
 */
const patchMembershipPayment = async ({
    type,
    amount,
    date,
    membershipPaymentId,
    offset,
    accessToken,
}: PatchMembershipPaymentApiType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    const data = await createDataObject({ type, amount, date, offset });
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipPayments/update/${membershipPaymentId}`,
            headers: {
                authorization: accessToken,
            },
            data: data,
        });
        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 412: // 수강권 권한 없음
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return null;
        }
    }
};
export /**
 *
 * @brief 관리자의 수강권 내역 삭제
 * https://github.com/mustMoveProject/rappo-project/blob/develop/src/docs/MembershipPayment.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%EC%88%98%EA%B0%95%EA%B6%8C-%EB%82%B4%EC%97%AD-%EC%88%98%EC%A0%95
 */
const deleteMembershipPayment = async ({
    membershipPaymentId,
    accessToken,
}: {
    membershipPaymentId: string;
    accessToken: string;
}): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_SERVER}/v1/membershipPayments/delete/${membershipPaymentId}`,
            headers: {
                authorization: accessToken,
            },
        });
        if (response.status === 204) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 412: // 수강권 권한 없음
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return null;
        }
    }
};
export type GetSalesListParams = {
    startDate: string;
    endDate: string;
    userId: string | 'all';
    role: 'coach' | 'etc' | 'all';
    centerId: string;
};

export type PatchMembershipRefundAndUpgradedParams = {
    membershipId: string;
    session: number;
    amount: number;
    date: string;
    accessToken: string;
};

export type PostSplitPaymentParams = {
    membershipId: string;
    amount: number;
    date: string;
    accessToken: string;
};

export type PatchMembershipPaymentApiType = {
    // "type": "PARTIAL_PAYMENT", // 'PARTIAL_REFUND' 부분환불 | 'FULL_REFUND' 전체환불 | 'UPGRADED' 업그레이드 | 'PARTIAL_PAYMENT' 분할결제
    // "amount": 99000,
    // "date": "2025-01-23T15:00:00.000+00:00"
    type: SalesStatus;
    amount: number;
    date: string;
    offset?: number;
    membershipPaymentId: string;
    accessToken: string;
};
