import React, { useEffect } from 'react';
import SvgIcon from '../../components/SvgIcon';
import { colors } from '../../lib/colors';
import dayjs, { Dayjs } from 'dayjs';
import { DemiLightText, MediumText, RegularText } from '../../lib/Text';
import { Col, convertToKRW, Row } from '../../lib/utils';
import Dropdown from '../../components/DropDown/DropDown';
import { CodeValueType } from '../../types/types';
import NewProfileImage from '../../components/NewProfileImage';
import ModalSalaryCalIncentive from './ModalSalaryCalIncentive';
import { CommissionType } from '../../api/salesCommision';
import ModalAddEtcSalary from './ModalAddEtcSalary';
import { TooltipIcon } from './SettlementCalendar';
import { SalaryCalcData } from '../../api/salaryCalc';
import useSalaryCalcMutationService from '../../services/useSalaryCalcMutationService';
import { useLocation } from 'react-router-dom';
import { MenuType } from '../Schedule/SettlementPage';
import { cloneDeep } from 'lodash';
import * as XLSX from 'xlsx'; // 엑셀 변환 라이브러리
import { saveAs } from 'file-saver';

type Props = {
    date: string;
    onMonthNavigate: (date: Date | string | Dayjs) => void;
    coachList: CodeValueType[];
    selectedCoach?: CodeValueType;
    onCoachSelect: (coach: CodeValueType) => void;
    salaryCalcData: SalaryCalcData;
    refresh: any;
    onClickExcel: (
        value: {
            coachName: string;
            settlement: number;
            incentive: number;
            incentiveType: CommissionType;
            classFee: number;
            baseSalary: number;
            etcBonus: { name: string; amount: number }[];
            deduction: number;
            finalSalary: number;
            isTaxable: boolean;
        },
        coachId: string
    ) => void;
};
const SalaryCalculationPage = ({
    date,
    onMonthNavigate,
    coachList,
    selectedCoach,
    onCoachSelect,
    salaryCalcData,
    refresh,
    onClickExcel,
}: Props) => {
    const { postSalaryCalcsMutation } = useSalaryCalcMutationService({
        onPostSalaryCalcsSuccess() {
            console.log('저장완료');
        },
    });

    const bassicSalaryRef = React.useRef<HTMLInputElement>(null);
    // 매출액
    const settlement = salaryCalcData?.totalSales ?? 0;
    // 매출 인센티브
    const [incentive, setIncentive] = React.useState(salaryCalcData?.incentive ?? 0);
    // 인센 타입
    const [incentiveType, setIncentiveType] = React.useState<CommissionType>(
        salaryCalcData?.incentiveType ?? 'percentage'
    );
    // 수업료
    const classFee = salaryCalcData?.totalScheduleFee ?? 0;
    // 기본급
    const [baseSalary, setBaseSalary] = React.useState(salaryCalcData?.baseSalary ?? 0);
    // 강사명
    const coachName = selectedCoach?.value ?? '';
    // 강사 프로필
    const coachProfile = selectedCoach?.object?.profileImageUrl ?? undefined;

    // 3.3% 공제
    const [deduction, setDeduction] = React.useState(0);

    // 기타, etcBonus, _id 제거
    const [etcBonus, setEtcBonus] = React.useState<{ name: string; amount: number }[]>(
        () => salaryCalcData?.etcBonus.map((bonus) => ({ name: bonus.name, amount: bonus.amount })) ?? []
    );
    // 3.3% 공제 여부
    const [isTaxable, setIsTaxable] = React.useState(false);
    // 최종 급여액
    const [finalSalary, setFinalSalary] = React.useState(0);

    // 기타 수당 선택시 이름, 값
    const [selectedEtcBonus, setSelectedEtcBonus] = React.useState<{ name: string; amount: number } | undefined>(
        undefined
    );

    const isPercentType = incentiveType === 'percentage';

    // 인센티브 모달
    const [isIncentiveModal, setIsIncentiveModal] = React.useState(false);
    // 기타 수당 모달
    const [isEtcBonusModal, setIsEtcBonusModal] = React.useState(false);

    // 바뀐값들만 파라미터로 넘기기
    const onSave = ({
        _incentive = incentive,
        _incentiveType = incentiveType,
        _baseSalary = baseSalary,
        _etcBonus = etcBonus,
        _isTaxable = isTaxable,
    }: {
        _incentive?: number;
        _incentiveType?: CommissionType;
        _baseSalary?: number;
        _etcBonus?: { name: string; amount: number }[];
        _isTaxable?: boolean;
    }) => {
        postSalaryCalcsMutation.mutate({
            year: dayjs(date).year().toString(),
            month: (dayjs(date).month() + 1).toString(),
            incentive: _incentive,
            incentiveType: _incentiveType,
            baseSalary: _baseSalary,
            etcBonus: _etcBonus,
            isTaxable: _isTaxable,
            selectedCoachId: selectedCoach?.code ?? '',
        });
    };

    // 공제 계산, isTaxable가 true일때만 계산
    useEffect(() => {
        const _incentive = isPercentType ? Math.floor(settlement * (incentive / 100)) : Math.floor(incentive);

        // 소수점 제거
        setDeduction(
            Math.floor(
                (_incentive + classFee + baseSalary + etcBonus.reduce((acc, cur) => acc + cur.amount, 0)) * 0.033
            )
        );
        // setDeduction(
        //     (incentive + classFee + baseSalary + etcBonus.reduce((acc, cur) => acc + cur.amount, 0)) * 0.033
        // );
    }, [isTaxable, incentive, baseSalary, etcBonus]);

    // 최종 급여액 계산
    useEffect(() => {
        const _incentive = isPercentType ? Math.floor(settlement * (incentive / 100)) : Math.floor(incentive);

        if (isTaxable) {
            setFinalSalary(
                _incentive + classFee + baseSalary + etcBonus.reduce((acc, cur) => acc + cur.amount, 0) - deduction
            );
        } else {
            setFinalSalary(_incentive + classFee + baseSalary + etcBonus.reduce((acc, cur) => acc + cur.amount, 0));
        }
    }, [incentive, classFee, baseSalary, etcBonus, deduction, selectedCoach, isTaxable]);

    // useEffect(() => {
    //     return () => {

    //     };
    // }, [location, selectedMenu]);

    useEffect(() => {
        if (salaryCalcData) {
            setIncentive(salaryCalcData.incentive);
            setIncentiveType(salaryCalcData.incentiveType);
            setBaseSalary(salaryCalcData.baseSalary);
            setEtcBonus(salaryCalcData.etcBonus);
            setIsTaxable(salaryCalcData.isTaxable);
        }
    }, [salaryCalcData]);

    return (
        <div className="flex flex-col w-full h-full pt-[29px] px-[46px] bg-white rounded-[27px] mb-[50px] pb-[50px]">
            <Row className="items-center mb-[60px]">
                <MediumText fontSize={25} lineHeight={25} color={'#3D4244'} className="mr-[14px]">
                    {dayjs(date).format('YYYY년 MM월')}
                </MediumText>
                <div
                    className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[5px]"
                    onClick={() => {
                        onMonthNavigate(dayjs(date).subtract(1, 'month'));
                        onSave({});
                    }}
                >
                    <SvgIcon
                        name={'SvgLeftStrokeArrow'}
                        size={15}
                        fill={colors.trans}
                        stroke={'#70747C'}
                        strokeWidth={1.2}
                    />
                </div>

                <div
                    className="flex cursor-pointer bg-[#F4F5F7] w-[30px] h-[30px] justify-center items-center rounded-[4px] mr-[30px]"
                    onClick={() => {
                        onMonthNavigate(dayjs(date).add(1, 'month'));
                        onSave({});
                    }}
                >
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={15}
                        fill={colors.trans}
                        stroke={'#70747C'}
                        strokeWidth={1.2}
                    />
                </div>
                {coachList && coachList.length > 0 && selectedCoach && (
                    <Dropdown
                        initialSelected={selectedCoach.code}
                        options={coachList}
                        onSelect={(selected) => {
                            onCoachSelect(selected);
                        }}
                        bgColor="rgba(201, 219, 255, 0.4)"
                        textColor={colors.primary}
                        borderRadius={50}
                        totalText="모든 강사"
                        arrowColor={colors.primary}
                        className="py-[14px]"
                        textType="medium"
                        isAllOption={false}
                    />
                )}
            </Row>
            <Row className="flex w-full justify-between px-[56px]">
                <Col className="flex justify-start ">
                    <Row className="items-center mb-[36px]">
                        {/* 매출액 */}
                        <Row className="w-[297px] bg-[#F6F8FA] border border-solid border-[#D9D9D9B2] rounded-[9px] py-[17.5px] px-[23px] justify-between mr-[20px]">
                            <RegularText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#3D4244">
                                매출액
                            </RegularText>
                            <Row>
                                <RegularText
                                    fontSize={17}
                                    lineHeight={24.64}
                                    letterSpacing={-0.32}
                                    color="#3D4244"
                                    className="mr-[5px]"
                                >
                                    {convertToKRW(settlement)}
                                </RegularText>
                                <DemiLightText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#71757C">
                                    원
                                </DemiLightText>
                            </Row>
                        </Row>
                        {/* 인센티브 설정 */}
                        <Row
                            className="border border-solid border-[#E2E2E3] rounded-[19.5px] bg-[#434C54] py-[10px] px-[13px] items-center cursor-pointer"
                            onClick={() => setIsIncentiveModal(true)}
                        >
                            <SvgIcon name="SvgPercentage" size={17} fill={colors.white} className="mr-[7px]" />
                            <RegularText fontSize={13} lineHeight={18.82} letterSpacing={-0.32} color="white">
                                인센티브 설정
                            </RegularText>
                        </Row>
                    </Row>
                    <Row className="w-full bg-[#F6F8FA] border border-solid border-[#D9D9D9B2] rounded-[9px] py-[17.5px] px-[23px] justify-between mr-[20px] mb-[36px]">
                        <Row className="items-center">
                            <RegularText
                                fontSize={17}
                                lineHeight={24.64}
                                letterSpacing={-0.32}
                                color="#3D4244"
                                className="mr-[6px]"
                            >
                                매출 인센티브
                            </RegularText>
                            <TooltipIcon
                                title="매출액에 대한 인센티브 비율/금액을 적용하면 나타나는 금액"
                                fontSize={11}
                                lineHeight={16}
                                classNmae="px-[20px]"
                                fontType="demilight"
                            />
                            {isPercentType && (
                                <Row className=" items-center">
                                    <SvgIcon
                                        name="SvgPercentage"
                                        size={17}
                                        fill={colors.primary}
                                        className="ml-[14px] mr-[4px]"
                                    />
                                    <MediumText
                                        fontSize={15}
                                        lineHeight={21}
                                        letterSpacing={-0.32}
                                        color={colors.primary}
                                    >
                                        {`${incentive}%`}
                                    </MediumText>
                                </Row>
                            )}
                        </Row>
                        <Row className="items-center ">
                            {incentiveType ? (
                                <RegularText
                                    fontSize={17}
                                    lineHeight={24.64}
                                    letterSpacing={-0.32}
                                    color="#3D4244"
                                    className="mr-[11px]"
                                >
                                    {isPercentType
                                        ? convertToKRW(Math.floor(settlement * (incentive / 100)))
                                        : convertToKRW(Math.floor(incentive))}
                                </RegularText>
                            ) : (
                                <Row className="items-center ">
                                    <SvgIcon name="SvgPercentage" size={17} fill={'#8D95A0'} className="mr-[4px]" />

                                    <RegularText
                                        fontSize={17}
                                        lineHeight={24.64}
                                        letterSpacing={-0.32}
                                        color="#8D95A0"
                                        className="mr-[11px]"
                                    >
                                        인센티브를 설정해주세요
                                    </RegularText>
                                </Row>
                            )}
                            <DemiLightText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#71757C">
                                원
                            </DemiLightText>
                        </Row>
                    </Row>
                    <Row className="w-full bg-[#F6F8FA] border border-solid border-[#D9D9D9B2] rounded-[9px] py-[17.5px] px-[23px] justify-between mr-[20px] mb-[36px]">
                        <RegularText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#3D4244">
                            수업료
                        </RegularText>
                        <Row>
                            <RegularText
                                fontSize={17}
                                lineHeight={24.64}
                                letterSpacing={-0.32}
                                color="#3D4244"
                                className="mr-[11px]"
                            >
                                {convertToKRW(classFee)}
                            </RegularText>
                            <DemiLightText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#71757C">
                                원
                            </DemiLightText>
                        </Row>
                    </Row>
                    <Row
                        className="w-full  border border-solid border-[#D9D9D9B2] rounded-[9px] py-[17.5px] px-[23px] justify-between mr-[20px] mb-[36px] cursor-text hover:border-primary transition-colors duration-300 ease-in-out"
                        onClick={() => {
                            bassicSalaryRef?.current?.focus();
                        }}
                    >
                        <RegularText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#3D4244">
                            기본급
                        </RegularText>
                        <Row>
                            <input
                                ref={bassicSalaryRef}
                                value={convertToKRW(baseSalary)}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    // , 없앰
                                    value = value.replace(/,/g, '');
                                    // 숫자만 정규식
                                    if (!/^[0-9]*$/.test(value)) {
                                        return;
                                    }
                                    // 0 일때 0지우고 숫자 입력
                                    if (value === '0') {
                                        setBaseSalary(Number(value));
                                        return;
                                    }

                                    setBaseSalary(Number(value));
                                }}
                                className="font-noto-regular text-[17px] leading-[24px] text-[#3D4244] bg-trans text-end mr-[11px]"
                                maxLength={15}
                                placeholder="금액을 입력해주세요"
                                onBlur={() => {}}
                            />
                            <DemiLightText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#71757C">
                                원
                            </DemiLightText>
                        </Row>
                    </Row>
                    {etcBonus.map((bonus, index) => (
                        <AddContainer
                            key={index + bonus.name}
                            bonus={bonus}
                            index={index}
                            onClick={(index, bonus) => {
                                setSelectedEtcBonus(bonus);
                                setIsEtcBonusModal(true);
                            }}
                            onDelete={(index) => {
                                setEtcBonus((prev) => {
                                    const clone = cloneDeep(prev);
                                    const newBouns = clone.filter((_, i) => i !== index);
                                    onSave({ _etcBonus: newBouns });
                                    return newBouns;
                                });
                            }}
                        />
                    ))}
                    {/* 기타 수당 추가하기 버튼 */}
                    <AddButton
                        // onClick={() => setEtcBonus([...etcBonus, { name: '', amount: 0 }])}
                        onClick={() => {
                            setSelectedEtcBonus(undefined);
                            setIsEtcBonusModal(true);
                        }}
                    />
                </Col>
                <Col className="w-[487px]  border border-solid border-[#D9DDE6] rounded-tl-[21px] rounded-tr-[21px] pt-[26px] pb-[40px] ">
                    <Row className=" items-center mb-[16px] px-[48px]">
                        <NewProfileImage imageUrl={coachProfile} size={41} className="mr-[8px]" />
                        <MediumText fontSize={22} lineHeight={32} letterSpacing={-0.32} color={colors.Text_1}>
                            {coachName + ' 강사'}
                        </MediumText>
                    </Row>
                    <Col
                        className="h-[1px] w-[390px] self-center
                     bg-[#E4E4E4] "
                    />
                    <Row className="py-[13px] justify-between items-center px-[48px]">
                        <Row className="  items-center ">
                            <SvgIcon name="SvgSettlementHistory" size={17} fill={colors.white} />
                            <RegularText
                                fontSize={16}
                                lineHeight={23}
                                letterSpacing={-0.32}
                                color={colors.Text_1}
                                className="ml-[8px] mr-[5px]"
                            >
                                {dayjs(date).format('M')}월 급여내역
                            </RegularText>
                        </Row>

                        <Row
                            className={` items-center justify-center bg-[#F2F4F6] rounded-[7px] px-[15px] h-[33px]  cursor-pointer 
                         
                        `}
                            onClick={() => {
                                onClickExcel(
                                    {
                                        coachName,
                                        settlement,
                                        incentive,
                                        incentiveType,
                                        classFee,
                                        baseSalary,
                                        etcBonus,
                                        deduction,
                                        finalSalary,
                                        isTaxable,
                                    },
                                    selectedCoach?.code ?? 'all'
                                );
                            }}
                        >
                            <SvgIcon name={'SvgExcel'} size={16} fill={colors.trans} className="mr-[7px]" />
                            <RegularText fontSize={14} lineHeight={20} color="#3D4244" letterSpacing={-0.32}>
                                엑셀 다운
                            </RegularText>
                        </Row>
                    </Row>
                    <Col className="h-[1px] bg-[#E4E4E4] w-[390px] self-center" />
                    {/* 급여내역, 스크롤 */}
                    <Col className="overflow-y-auto my-[30px] mr-[22px] scrollbar-thumb-[#B4B4B6] scrollbar-track-gray-200 scrollbar scrollbar-w-[5px]  scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-h-[10px] ">
                        <Col className="h-[229px]   pl-[48px] pr-[26px]">
                            <Row className=" justify-between mb-[12px]">
                                <Row>
                                    <DemiLightText
                                        fontSize={16}
                                        lineHeight={23}
                                        letterSpacing={-0.32}
                                        color={colors.Text_1}
                                    >
                                        매출 인센티브
                                    </DemiLightText>
                                    {isPercentType && (
                                        <Row className=" items-center">
                                            <SvgIcon
                                                name="SvgPercentage"
                                                size={17}
                                                fill={colors.primary}
                                                className="ml-[7px] mr-[4px]"
                                            />
                                            <MediumText
                                                fontSize={15}
                                                lineHeight={21}
                                                letterSpacing={-0.32}
                                                color={colors.primary}
                                            >
                                                {`${incentive}%`}
                                            </MediumText>
                                        </Row>
                                    )}
                                </Row>
                                <DemiLightText
                                    fontSize={16}
                                    lineHeight={23}
                                    letterSpacing={-0.32}
                                    color={colors.Text_1}
                                >
                                    {(isPercentType
                                        ? convertToKRW(Math.floor(settlement * (incentive / 100)))
                                        : convertToKRW(Math.floor(incentive))) + ' 원'}
                                </DemiLightText>
                            </Row>
                            <Row className=" justify-between mb-[12px]">
                                <DemiLightText
                                    fontSize={16}
                                    lineHeight={23}
                                    letterSpacing={-0.32}
                                    color={colors.Text_1}
                                >
                                    수업료
                                </DemiLightText>
                                <DemiLightText
                                    fontSize={16}
                                    lineHeight={23}
                                    letterSpacing={-0.32}
                                    color={colors.Text_1}
                                >
                                    {convertToKRW(classFee)} 원
                                </DemiLightText>
                            </Row>
                            <Row className=" justify-between mb-[12px]">
                                <DemiLightText
                                    fontSize={16}
                                    lineHeight={23}
                                    letterSpacing={-0.32}
                                    color={colors.Text_1}
                                >
                                    기본급
                                </DemiLightText>
                                <DemiLightText
                                    fontSize={16}
                                    lineHeight={23}
                                    letterSpacing={-0.32}
                                    color={colors.Text_1}
                                >
                                    {convertToKRW(baseSalary)} 원
                                </DemiLightText>
                            </Row>
                            {etcBonus.map((bonus, index) => (
                                <Row className=" justify-between mb-[12px]" key={index + bonus.name}>
                                    <DemiLightText
                                        fontSize={16}
                                        lineHeight={23}
                                        letterSpacing={-0.32}
                                        color={colors.Text_1}
                                    >
                                        {bonus.name}
                                    </DemiLightText>
                                    <DemiLightText
                                        fontSize={16}
                                        lineHeight={23}
                                        letterSpacing={-0.32}
                                        color={colors.Text_1}
                                    >
                                        {convertToKRW(bonus.amount)} 원
                                    </DemiLightText>
                                </Row>
                            ))}
                            {isTaxable && (
                                <Row className=" justify-between mb-[12px]">
                                    <DemiLightText
                                        fontSize={16}
                                        lineHeight={23}
                                        letterSpacing={-0.32}
                                        color={colors.Text_1}
                                    >
                                        3.3% 공제
                                    </DemiLightText>
                                    <DemiLightText
                                        fontSize={16}
                                        lineHeight={23}
                                        letterSpacing={-0.32}
                                        color={colors.Text_1}
                                    >
                                        {'-' + convertToKRW(deduction) + ' 원'}
                                    </DemiLightText>
                                </Row>
                            )}
                        </Col>
                    </Col>
                    <Col className="h-[1px]  bg-[#E4E4E4] mb-[52px] w-[390px] self-center" />
                    <Row className=" items-center justify-between mb-[13px] px-[48px]">
                        <MediumText fontSize={18} lineHeight={26} letterSpacing={-0.32} color={colors.primary}>
                            최종 급여액
                        </MediumText>
                        <MediumText fontSize={26} lineHeight={37} letterSpacing={-0.32} color={colors.primary}>
                            {convertToKRW(finalSalary)}원
                        </MediumText>
                    </Row>
                    <Row className=" self-end pr-[48px]">
                        <label className={`relative inline-flex items-center cursor-pointer mr-[8px] `}>
                            <div
                                onClick={() => {
                                    setIsTaxable(!isTaxable);
                                    onSave({ _isTaxable: !isTaxable });
                                }}
                                className={`w-[16px] h-[16px] border-[1px] border-[#D2D8DE] border-solid rounded-md flex items-center justify-center 
                                    ${isTaxable ? 'bg-[#3D4244]' : 'bg-trans'}
                                    `}
                                style={{
                                    width: 18,
                                    height: 18,
                                }}
                            >
                                {isTaxable ? (
                                    <svg
                                        className=" text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            xmlns="http://www.w3.org/2000/svg"
                                            d="M5 11L10.6667 16.6666L20 6"
                                            stroke="white"
                                            strokeWidth={1.5}
                                        />
                                    </svg>
                                ) : (
                                    <div className="w-[15px] h-[15px]" />
                                )}
                            </div>
                        </label>
                        <DemiLightText fontSize={14} lineHeight={20} letterSpacing={-0.32} color={colors.Text_1}>
                            3.3% 공제
                        </DemiLightText>
                    </Row>
                </Col>
            </Row>
            {isIncentiveModal && (
                <ModalSalaryCalIncentive
                    setIsVisible={setIsIncentiveModal}
                    incentive={incentive.toString()}
                    type={incentiveType}
                    onClickConfirm={(incen, incenType) => {
                        setIncentive(Number.parseInt(incen));
                        setIncentiveType(incenType);
                        onSave({ _incentive: Number.parseInt(incen), _incentiveType: incenType });
                    }}
                />
            )}
            {isEtcBonusModal && (
                <ModalAddEtcSalary
                    setIsVisible={setIsEtcBonusModal}
                    selectedEtcBonus={selectedEtcBonus}
                    onClickConfirm={(value, type) => {
                        const clone = cloneDeep(etcBonus);

                        if (type === 'new') {
                            // setEtcBonus([...etcBonus, value]);
                            clone.push(value);
                        } else {
                            // const index = etcBonus.findIndex((bonus) => bonus.name === value.name);
                            // etcBonus[index] = value;
                            // setEtcBonus([...etcBonus]);
                            const index = clone.findIndex((bonus) => bonus.name === value.name);
                            clone[index] = value;
                        }
                        setEtcBonus(clone);
                        onSave({ _etcBonus: clone });
                    }}
                />
            )}
        </div>
    );
};

export default SalaryCalculationPage;

const AddContainer = ({
    bonus,
    index,
    onClick,
    onDelete,
}: {
    bonus: { name: string; amount: number };
    index: number;
    onClick?: (index: number, bonus: { name: string; amount: number }) => void;
    onDelete?: (index: number) => void;
}) => {
    // return (
    //     <Row className="w-full  border border-solid border-[#D9D9D9B2] rounded-[9px] py-[17.5px] px-[23px] justify-between mr-[20px] mb-[36px]">
    //         <input
    //             value={name}
    //             onChange={(e) => setName(e.target.value)}
    //             className="font-noto-regular text-[17px] leading-[24px] text-[#3D4244] bg-trans"
    //             maxLength={15}
    //             onBlur={() => onFocusOut(index, { name, amount })}
    //             placeholder="수당명을 입력해주세요"
    //         />
    //         <Row>
    //             <input
    //                 value={amount}
    //                 onChange={(e) => setAmount(Number(e.target.value))}
    //                 className="font-noto-regular text-[17px] leading-[24px] text-[#3D4244] bg-trans text-end mr-[5px]"
    //                 maxLength={15}
    //                 onBlur={() => onFocusOut(index, { name, amount })}
    //             />
    //             <DemiLightText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#71757C">
    //                 원
    //             </DemiLightText>
    //         </Row>
    //     </Row>
    // );
    return (
        <Row className="w-full items-center relative  mb-[36px]">
            <Row
                key={index + bonus.name}
                className="w-full  border border-solid border-[#D9D9D9B2] rounded-[9px] py-[17.5px] px-[23px] justify-between   hover:border-primary transition-colors duration-300 ease-in-out cursor-pointer"
                onClick={() => onClick && onClick(index, bonus)}
            >
                <RegularText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#3D4244">
                    {bonus.name}
                </RegularText>
                <Row>
                    <RegularText
                        fontSize={17}
                        lineHeight={24.64}
                        letterSpacing={-0.32}
                        color="#3D4244"
                        className="mr-[11px]"
                    >
                        {convertToKRW(bonus.amount)}
                    </RegularText>
                    <DemiLightText fontSize={17} lineHeight={24.64} letterSpacing={-0.32} color="#71757C">
                        원
                    </DemiLightText>
                </Row>
            </Row>
            <SvgIcon
                name="SvgCircleX"
                size={17}
                fill={'#434C54'}
                className=" absolute right-[-33px] cursor-pointer "
                onClick={() => onDelete && onDelete(index)}
            />
        </Row>
    );
};

// 기타 수당 추가하기
const AddButton = ({ onClick }: { onClick: () => void }) => {
    // 호버 됐을때
    const [isHover, setIsHover] = React.useState(false);
    return (
        <Row
            className=" items-center self-center cursor-pointer"
            onClick={onClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <SvgIcon
                name="SvgPlusInCircle"
                size={20}
                fill={isHover ? colors.primary : colors.Text_1}
                className="mr-[11px]"
            />
            <DemiLightText
                fontSize={17}
                lineHeight={24.64}
                letterSpacing={-0.32}
                color={isHover ? colors.primary : colors.Text_1}
            >
                기타 수당 추가하기(ex. 블로그 수당)
            </DemiLightText>
        </Row>
    );
};
