import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../modal/Modal';
import { Col, Row } from '../../lib/utils';
import { MediumText, RegularText } from '../../lib/Text';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onClickChangePartialPay: () => void;
};

const ModalChangePartialPay = ({ setIsVisible, onClickChangePartialPay }: Props) => {
    return (
        <Modal setIsVisible={setIsVisible} whiteViewWidth={629} whiteViewHeight={231} borderRadius={25}>
            <Col className="p-[35px]">
                <MediumText fontSize={28} lineHeight={38} color="#3D4244" letterSpacing={-0.32}>
                    분할 결제 수강권으로 변경하시겠습니까?
                </MediumText>
                <Row className=" absolute right-[35px] bottom-[35px] ">
                    <Col
                        className="flex justify-center items-center self-end w-[119px] h-[48px]  bg-[#F2F4F6] rounded-[9px] cursor-pointer mr-[18px]"
                        onClick={() => {
                            setIsVisible(false);
                        }}
                    >
                        <RegularText fontSize={20} lineHeight={23} color="#3D4244">
                            취소
                        </RegularText>
                    </Col>
                    <Col
                        className="flex justify-center items-center self-end w-[150px] h-[48px]  bg-primary rounded-[9px] cursor-pointer"
                        onClick={() => {
                            onClickChangePartialPay && onClickChangePartialPay();
                            setIsVisible(false);
                        }}
                    >
                        <RegularText fontSize={20} lineHeight={23} color="white">
                            변경하기
                        </RegularText>
                    </Col>
                </Row>
            </Col>
        </Modal>
    );
};

export default ModalChangePartialPay;
