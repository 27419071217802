import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ScheduleTop from '../../components/Schedule/ScheduleTop';
import DailySchedule from '../../components/Schedule/Daily/DailySchedule';
import { Row, getItemWithExpiration } from '../../lib/utils';
import styled, { keyframes } from 'styled-components';
import dayjs from 'dayjs';
import WeeklySchedule from '../../components/Schedule/Weekly/WeeklySchedule';
import { I_FLOATING, I_FLOATING_HOVER, I_FLOATING_ONCLICK } from '../../types/images';
import ModalCreateClass from '../../components/Schedule/ModalCreateClass';
import { useNavigate } from 'react-router-dom';
import Calendar from '../../components/Calendar/Calendar';
import { colors } from '../../lib/colors';
import WeeklyCalendar from '../../components/WeeklySchedule/WeeklyCalendar';
import ScheduleDetailModal from '../../components/Schedule/DetailModal/ScheduleDetailModal';
import RequestDetailModal from '../../components/Schedule/DetailModal/RequestDetailModal';
import OffEtcDetailModal from '../../components/Schedule/DetailModal/OffEtcDetailModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    approveScheduleRequestAPI,
    getDailyScheduleAPI,
    getWeeklyScheduleAPI,
    rejectScheduleRequestAPI,
} from '../../api/schedule';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    animation: ${fadeIn} 1s ease; /* 애니메이션 적용 */
    transition: width 0.5s ease;
`;

const SchedulePage = ({ setIsLoading, createAlert, createTwoButtonAlert }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    const navigate = useNavigate();
    const location = useLocation();

    // 캘린더 페이지의 일간 주간 타입 정보
    const calendarType = location.state?.calendarType;

    // 일정 등록 / 수정 시 받아올 시간
    const calendarTimeInfo = location.state?.calendarTimeInfo;

    // 일정 페이지로 갔다가 되돌아올 때 받아올 날짜 및 스크롤 정보
    const calendarSelectedDay = location.state?.calendarSelectedDay;
    const calendarScrollY = location.state?.calendarScrollY;

    const [currentDate, setCurrentDate] = useState(dayjs());
    const [pageType, setPageType] = useState<'daily' | 'weekly'>('daily');
    const [selectedDay, setSelectedDay] = useState<any>(new Date());
    const [selectedSchedule, setSelectedSchedule] = useState<any>(null);

    // 플로팅 버튼 누르면 나오는 모달 boolean
    const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
    const [isCalendar, setIsCalendar] = useState<boolean>(false);

    // 수업일정 자세히보기 모달
    const [detailObject, setDetailObject] = useState<any>(null);
    const [isScheduleDetailModal, setIsScheduleDetailModal] = useState<boolean>(false);

    const [isRequestDetailModal, setIsRequestDetailModal] = useState<boolean>(false);

    // OFF ,  일반일정 자세히보기 모달
    const [isOffEtcDetailModal, setIsOffEtcDetailModal] = useState<boolean>(false);
    const [scrollY, setScrollY] = useState<number>(0);

    /***** 일간일정 속성 */

    /** 초기값 설정 */
    const [dailyScheduleList, setDailyScheduleList] = useState<any>(null);
    const [dailyScheduleCount, setDailyScheduleCount] = useState<number>(0);
    const [gridData, setGridData] = useState<any>(null);

    /** 페이징 처리 */
    const pageSize = 8;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const [dailyCurrentData, setDailyCurrentData] = useState<any>(null);
    let endPage: number = 0;
    endPage = dailyScheduleList && Math.ceil(dailyScheduleList.length / pageSize);

    // 일간 강사 스케줄 조회 API : (GET)
    const getDailySchedule = useQuery(
        ['getDailyScheduleAPI', selectedDay],
        async () =>
            await getDailyScheduleAPI(authObject.centerId, dayjs(selectedDay).format('YYYY-MM-DD'), authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    setDailyScheduleList(res.data.dailySchedules);
                    setDailyScheduleCount(res.data.dailySchedulesCount);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    const handleNextPage = () => {
        if (currentPage < Math.ceil(dailyScheduleList.length / pageSize)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // 호버클릭시 : 하단플로팅 버튼과 동일한 모달
    const onClickHover = (scheduleTime: any, coachName: string, coachId: string) => {
        onClickFloatingButton();
        setSelectedSchedule({
            scheduleStartTime: dayjs(scheduleTime).format('YYYY-MM-DD HH:mm'),
            coachName: coachName,
            coachId: coachId,
        });
    };

    /***** 일간일정 속성 끝 */

    /***** 주간일정 속성 */
    const [weeklyGridData, setWeeklyGridData] = useState<any>(null); // 그리드 그리는 데이터
    const [weeklyScheduleList, setWeeklyScheduleList] = useState<any>(null);
    const [weeklyScheduleCount, setWeeklyScheduleCount] = useState<number>(0);

    const [lengthArr, setLengthArr] = useState<any>(); // 각시간마다의 길이만 담고있는 배열

    const [currentHeight, setCurrentHeight] = useState<number>(0);
    const [totalHeight, setTotalHeight] = useState<number>(0);

    // API : (GET) 주간 강사 스케줄 조회
    const getWeeklySchedule = useQuery(
        ['getWeeklyScheduleAPI', selectedDay],
        async () =>
            await getWeeklyScheduleAPI(
                authObject.centerId,
                dayjs(selectedDay).startOf('week').format('YYYY-MM-DD'),
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const apiData = res.data.weeklySchedules;
                    setWeeklyScheduleList(apiData);
                    setWeeklyScheduleCount(res.data.weeklySchedulesCount);

                    if (apiData) {
                        let weeklyGridData: any = Array.from({ length: 24 }, (_, rowIndex) =>
                            Array.from({ length: 8 }, (_, colIndex) => ({
                                id: `${rowIndex}-${colIndex}`,
                                content:
                                    colIndex === 0
                                        ? rowIndex === 0
                                            ? `자정 12시`
                                            : rowIndex < 12
                                            ? `오전 ${rowIndex.toString().padStart(2, '0')}시`
                                            : rowIndex === 12
                                            ? `정오 ${rowIndex}시`
                                            : `오후 ${(rowIndex - 12).toString().padStart(2, '0')}시`
                                        : `${rowIndex}-${colIndex}`,
                                schedules: null,
                            }))
                        );

                        // weeklyGridData[세로열 (시간 0~23)][가로열 (날짜 id열 + 7일)]

                        for (let i = 0; i < apiData.length; i++) {
                            for (let j = 0; j < apiData[i].schedules.length; j++) {
                                weeklyGridData[j][i + 1] = apiData[i].schedules[j];
                            }
                        }
                        setWeeklyGridData(weeklyGridData);

                        // 시간 배열생성
                        let arr = Array.from({ length: 24 }, () => new Array(7).fill(null));
                        for (let i = 0; i < apiData.length; i++) {
                            for (let j = 0; j < 24; j++) {
                                const schedules = apiData[i].schedules[j];
                                let scheduleCount = 0;
                                let lastCoachId = '';
                                let lastStartTime = '';
                                for (const schedule of schedules) {
                                    if (schedule.coachId == lastCoachId && schedule.startTime == lastStartTime) {
                                        continue;
                                    }
                                    lastCoachId = schedule.coachId;
                                    lastStartTime = schedule.startTime;
                                    scheduleCount++;
                                }
                                arr[j][i] = scheduleCount;
                            }
                        }

                        setLengthArr(arr);

                        // 블러처리를 위한 높이구하기
                        let totalHeightArr = [];
                        let currentHeightArr = [];
                        let currentHour = parseInt(dayjs().format('H'));

                        for (let i = 0; i < arr.length; i++) {
                            totalHeightArr.push(Math.max(...arr[i]));
                        }

                        for (let i = 0; i < currentHour; i++) {
                            currentHeightArr.push(Math.max(...arr[i]));
                        }

                        const totalSum = totalHeightArr.reduce(
                            (acc: number, number: number) => acc + (number === 0 ? 94 : 94 + (number - 1) * 94),
                            0
                        );

                        const currentSum = currentHeightArr.reduce(
                            (acc: number, number: number) => acc + (number === 0 ? 94 : 94 + (number - 1) * 94),
                            0
                        );
                        // 현재시간 이 포함된 전에 스케줄박스까지의 높이  + 현재 시간에 걸쳐진 박스에 높이

                        if (
                            dayjs(selectedDay).isBefore(dayjs().endOf('week')) &&
                            dayjs(selectedDay).isAfter(dayjs().startOf('week'))
                        ) {
                            setCurrentHeight(currentSum);
                            setTotalHeight(totalSum);
                        } else {
                            setCurrentHeight(0);
                            setTotalHeight(0);
                        }
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 로딩 플래그
    useEffect(() => {
        if (getWeeklySchedule) {
            if (getWeeklySchedule.isLoading) {
                if (authObject?.testAccount) {
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            } else {
                setIsLoading(false);
            }
        }
    }, [getWeeklySchedule]);
    /***** 주간일정 속성 끝 */

    // 플로팅 버튼 클릭이벤트
    const onClickFloatingButton = () => {
        setIsCreateModal(true);
    };

    // 날짜 클릭시 달력나오게
    const onClickCalendar = () => {
        setIsCalendar(true);
    };

    // 수업일정 클릭이벤트
    const onClickSchedule = (item: any) => {
        setDetailObject(item);
        setIsScheduleDetailModal(true);
    };

    // 수업신청 클릭이벤트
    const onClickRequest = (item: any) => {
        setDetailObject(item);
        setIsRequestDetailModal(true);
    };

    // OFF, 일반일정 클릭이벤트
    const onClickEtcOffSchedule = (item: any) => {
        setDetailObject(item);
        setIsOffEtcDetailModal(true);
    };

    const onClickSubDate = () => {
        if (pageType === 'daily') {
            setSelectedDay(() => dayjs(selectedDay).subtract(1, 'day'));
        } else {
            setSelectedDay(() => dayjs(selectedDay).subtract(1, 'week'));
        }
    };

    const onClickAddDate = () => {
        if (pageType === 'daily') {
            setSelectedDay(() => dayjs(selectedDay).add(1, 'day'));
        } else {
            setSelectedDay(() => dayjs(selectedDay).add(1, 'week'));
        }
    };

    const onClickDateToToday = () => {
        setSelectedDay(dayjs());
    };

    const queryClient = useQueryClient();

    // 스케줄 신청 수락 / 거절하기

    // 스케줄 신청 수락 API : (patch)
    const approveScheduleRequest = useMutation({
        mutationFn: async (scheduleObject: any) =>
            await approveScheduleRequestAPI(authObject?.centerId, scheduleObject._id, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
                setIsRequestDetailModal(false);
                createAlert('등록 완료!', '대기중인 수업이 성공적으로 등록되었어요!', false);
            }

            if (res.status === 400) {
                createAlert('오류', '해당 시간에는 스케줄을 생성 할 수 없습니다', true);
            }

            if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert('중복', '해당 시간에 이미 스케줄이 등록되어있습니다!\n다시 한번 확인해주세요!', true);
            }
        },
        onError: () => {
            createAlert('오류', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
        },
    });

    // 스케줄 신청 거절 API : (patch)
    const rejectScheduleRequest = useMutation({
        mutationFn: async (scheduleObject: any) =>
            await rejectScheduleRequestAPI(authObject?.centerId, scheduleObject._id, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
                queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
            }

            if (res.status === 400) {
                createAlert('오류', '해당 시간에는 스케줄을 생성 할 수 없습니다', true);
            }

            if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert('중복', '해당 시간에 이미 스케줄이 등록되어있습니다!\n다시 한번 확인해주세요!', true);
            }
        },
        onError: () => {
            createAlert('', '오류가 발생하였습니다!\n다시 한번 등록해주세요!', true);
        },
    });

    // 수업일정 신청 수락하기 버튼 클릭이벤트
    const onClickApproveScheduleRequest = (scheduleObject: any) => {
        approveScheduleRequest.mutate(scheduleObject);
    };

    // 수업일정 신청 거절하기 버튼 클릭이벤트
    const onClickRejectScheduleRequest = (scheduleObject: any) => {
        createAlert(
            '수업일정 거절을 진행하시겠습니까?',
            '대기중인 수업을 거절하시면 회원님께 알림이 전달됩니다!',
            true,
            '확인',
            () => {
                rejectScheduleRequest.mutate(scheduleObject);
                setIsRequestDetailModal(false);
            },
            true,
            '취소',
            () => {}
        );
    };

    let grid = Array.from({ length: 24 }, (_, rowIndex) => {
        return Array.from({ length: 9 }, (_, colIndex) => ({
            id: `${rowIndex}-${colIndex}`,
            content:
                colIndex === 0
                    ? rowIndex === 0
                        ? `자정 12시`
                        : rowIndex < 12
                        ? `오전 ${rowIndex.toString().padStart(2, '0')}시`
                        : rowIndex === 12
                        ? `정오 ${rowIndex}시`
                        : `오후 ${(rowIndex - 12).toString().padStart(2, '0')}시`
                    : `${rowIndex}-${colIndex}`,
            coachName: null,
            coachId: null,
            coachProfileImageUrl: null,
        }));
    });

    // 페이징된 데이터 기반 그리드 데이터 정제
    useEffect(() => {
        if (dailyCurrentData) {
            let grid = Array.from({ length: 24 }, (_, rowIndex) => {
                return Array.from({ length: 9 }, (_, colIndex) => ({
                    id: `${rowIndex}-${colIndex}`,
                    content:
                        colIndex === 0
                            ? rowIndex === 0
                                ? `자정 12시`
                                : rowIndex < 12
                                ? `오전 ${rowIndex.toString().padStart(2, '0')}시`
                                : rowIndex === 12
                                ? `정오 ${rowIndex}시`
                                : `오후 ${(rowIndex - 12).toString().padStart(2, '0')}시`
                            : `${rowIndex}-${colIndex}`,
                    coachName: null,
                    coachId: null,
                    coachProfileImageUrl: null,
                }));
            });

            for (let i = 0; i < dailyCurrentData.length; i++) {
                grid[0][i + 1].coachName = dailyCurrentData[i].coachName;
                grid[0][i + 1].coachId = dailyCurrentData[i].coachId;
                grid[0][i + 1].coachProfileImageUrl = dailyCurrentData[i].coachProfileImageUrl;
            }

            setGridData(grid);
        }
    }, [dailyCurrentData]);

    // // 데모버전으로 진입시
    // useEffect(() => {
    //     if (authObject?.testAccount) {
    //         setDailyScheduleList(dailySchedulesDummyData);
    //         setDailyScheduleCount(dailySchedulesCountDummyData);
    //     }
    // }, [authObject?.testAccount]);

    // 강사 8명씩 자르기
    useEffect(() => {
        if (dailyScheduleList) {
            let filterData = dailyScheduleList.slice(startIndex, endIndex);
            setDailyCurrentData(filterData);
        }
    }, [dailyScheduleList, currentPage]);

    // 로딩 플래그
    useEffect(() => {
        if (getDailySchedule) {
            if (getDailySchedule.isLoading) {
                if (authObject?.testAccount) {
                    setIsLoading(false);
                } else {
                    setIsLoading(true);
                }
            } else {
                setIsLoading(false);
            }
        }
    }, [getDailySchedule]);

    // 스크롤 할시 현재 스크롤위치저장
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 24) {
                setScrollY(window.scrollY);
            } else {
                setScrollY(0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // 시간이 변경되었을 때 스크롤
    useEffect(() => {
        if (!!calendarType && !!calendarTimeInfo) {
            const startHour = dayjs(calendarTimeInfo).hour();
            const calendarCellHeight = calendarType === 'daily' ? 120 : 98;
            const calendarTimeScrollY =
                startHour < 2
                    ? calendarCellHeight * startHour
                    : calendarCellHeight * startHour - calendarCellHeight * 2;
            const calendarTimeSelectedDay = dayjs(calendarTimeInfo);

            setPageType(calendarType);
            setSelectedDay(calendarTimeSelectedDay);
            setTimeout(() => {
                window.scrollTo(0, calendarTimeScrollY);
            }, 500);
        }
    }, [calendarTimeInfo]);

    useEffect(() => {
        if (!!calendarType && !!calendarSelectedDay && !!calendarScrollY) {
            setPageType(calendarType);
            setSelectedDay(dayjs(calendarSelectedDay));
            setTimeout(() => {
                window.scrollTo(0, calendarScrollY);
            }, 500);
        }
    }, [calendarSelectedDay, calendarScrollY]);

    useEffect(() => {
        if (pageType && selectedDay) {
            queryClient.invalidateQueries({ queryKey: ['getDailyScheduleAPI'] });
            queryClient.invalidateQueries({ queryKey: ['getWeeklyScheduleAPI'] });
        }
    }, [pageType, selectedDay]);

    // 호버시
    const handleMouseOver = (e: any) => {
        e.currentTarget.src = I_FLOATING_HOVER;
    };

    // 디폴트 컬러
    const handleMouseOut = (e: any) => {
        e.currentTarget.src = I_FLOATING;
    };

    // 액티브
    const handleMouseDown = (e: any) => {
        e.currentTarget.src = I_FLOATING_ONCLICK;
    };

    // 디폴트 컬러
    const handleMouseUp = (e: any) => {
        e.currentTarget.src = I_FLOATING;
    };

    return (
        <Row style={{ width: '100%', paddingTop: 40 }}>
            <ColContainer style={{ width: '100%' }}>
                <ScheduleTop
                    pageType={pageType}
                    setPageType={setPageType}
                    currentDate={currentDate}
                    selectedDay={selectedDay}
                    onClickSubDate={onClickSubDate}
                    onClickAddDate={onClickAddDate}
                    onClickDateToToday={onClickDateToToday}
                    onClickCalendar={onClickCalendar}
                />

                {pageType === 'daily' && (
                    <DailySchedule
                        setIsLoading={setIsLoading}
                        grid={grid}
                        currentDate={currentDate}
                        authorization={authorization}
                        authObject={authObject}
                        createAlert={createAlert}
                        createTwoButtonAlert={createTwoButtonAlert}
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        selectedSchedule={selectedSchedule}
                        setSelectedSchedule={setSelectedSchedule}
                        onClickFloatingButton={onClickFloatingButton}
                        onClickSchedule={onClickSchedule}
                        onClickRequest={onClickRequest}
                        onClickEtcOffSchedule={onClickEtcOffSchedule}
                        onClickApproveScheduleRequest={onClickApproveScheduleRequest}
                        onClickRejectScheduleRequest={onClickRejectScheduleRequest}
                        scrollY={scrollY}
                        dailyCurrentData={dailyCurrentData}
                        gridData={gridData}
                        onClickHover={onClickHover}
                        currentPage={currentPage}
                        endPage={endPage}
                        handleNextPage={handleNextPage}
                        handlePrevPage={handlePrevPage}
                    />
                )}

                {pageType === 'weekly' && (
                    <WeeklySchedule
                        setIsLoading={setIsLoading}
                        grid={grid}
                        currentDate={currentDate}
                        authorization={authorization}
                        authObject={authObject}
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        onClickFloatingButton={onClickFloatingButton}
                        onClickSchedule={onClickSchedule}
                        onClickRequest={onClickRequest}
                        onClickEtcOffSchedule={onClickEtcOffSchedule}
                        onClickApproveScheduleRequest={onClickApproveScheduleRequest}
                        onClickRejectScheduleRequest={onClickRejectScheduleRequest}
                        scrollY={scrollY}
                        weeklyScheduleList={weeklyScheduleList}
                        weeklyGridData={weeklyGridData}
                        lengthArr={lengthArr}
                    />
                )}

                {/* 플로팅 버튼 클릭시 새로운 일정 생성 모달 */}
                {isCreateModal && (
                    <ModalCreateClass
                        modalVisible={isCreateModal}
                        setModalVisible={setIsCreateModal}
                        navigate={navigate}
                        createAlert={createAlert}
                        selectedSchedule={selectedSchedule}
                        setSelectedSchedule={setSelectedSchedule}
                        calendarType={pageType}
                        calendarSelectedDay={dayjs(selectedDay).format('YYYY-MM-DD')}
                        calendarScrollY={scrollY}
                    />
                )}

                {/* 달력 모달 pageType에 따른 분기 */}
                {isCalendar && pageType === 'daily' && (
                    <Calendar
                        selectedDay={new Date(selectedDay)}
                        setSelectedDay={setSelectedDay}
                        setIsCalendar={setIsCalendar}
                        isPrevMonth={true}
                        isNextMonth={true}
                        style={{
                            width: 420,
                            height: 370,
                            borderRadius: 24,
                            backgroundColor: colors.WHITE_50,
                            boxShadow: '8px 8px 24px 0px #00000052',

                            position: 'fixed',
                            zIndex: 1999,
                            marginTop: 70,
                            marginLeft: 230,
                        }}
                    />
                )}

                {isCalendar && pageType === 'weekly' && (
                    <WeeklyCalendar
                        selectedDay={new Date(selectedDay)}
                        setSelectedDay={setSelectedDay}
                        isPrevMonth={true}
                        isNextMonth={true}
                        setIsCalendar={setIsCalendar}
                        style={{
                            width: 420,
                            height: 370,
                            borderRadius: 24,
                            backgroundColor: colors.WHITE_50,
                            boxShadow: '8px 8px 24px 0px #00000052',

                            position: 'fixed',
                            zIndex: 1999,
                            marginTop: 70,
                            marginLeft: 230,
                        }}
                    />
                )}

                {/* 하단 플로팅 버튼 */}
                <Row
                    onClick={onClickFloatingButton}
                    style={{
                        width: 64,
                        height: 64,
                        position: 'fixed',
                        right: 64,
                        bottom: 64,
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={I_FLOATING}
                        style={{ width: 64, height: 64 }}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                    />
                </Row>
            </ColContainer>

            {isScheduleDetailModal && detailObject && (
                <ScheduleDetailModal
                    modalVisible={isScheduleDetailModal}
                    setModalVisible={setIsScheduleDetailModal}
                    detailObject={detailObject}
                    createAlert={createAlert}
                    createTwoButtonAlert={createTwoButtonAlert}
                    calendarType={pageType}
                    calendarSelectedDay={dayjs(selectedDay).format('YYYY-MM-DD')}
                />
            )}

            {isRequestDetailModal && detailObject && (
                <RequestDetailModal
                    modalVisible={isRequestDetailModal}
                    setModalVisible={setIsRequestDetailModal}
                    navigate={navigate}
                    detailObject={detailObject}
                    setDetailObject={setDetailObject}
                    onClickRejectScheduleRequest={onClickRejectScheduleRequest}
                    onClickApproveScheduleRequest={onClickApproveScheduleRequest}
                    createAlert={createAlert}
                    authObject={authObject}
                    calendarType={pageType}
                    calendarSelectedDay={dayjs(selectedDay).format('YYYY-MM-DD')}
                    calendarScrollY={scrollY}
                />
            )}

            {isOffEtcDetailModal && detailObject && (
                <OffEtcDetailModal
                    modalVisible={isOffEtcDetailModal}
                    setModalVisible={setIsOffEtcDetailModal}
                    navigate={navigate}
                    detailObject={detailObject}
                    setDetailObject={setDetailObject}
                    createAlert={createAlert}
                    authObject={authObject}
                    calendarType={pageType}
                    calendarSelectedDay={dayjs(selectedDay).format('YYYY-MM-DD')}
                    calendarScrollY={scrollY}
                />
            )}
        </Row>
    );
};

export default SchedulePage;
