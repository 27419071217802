import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import { Col, convertToKRW, Row } from '../../lib/utils';
import { BoldText, DemiLightText, MediumText, RegularText } from '../../lib/Text';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';
import { Body2Bold } from '../../lib/font';
import useMembershipPaymentsMutationService from '../../services/useMembershipPaymentsMutationService';
import NewCalendar from '../Calendar/NewCalendar';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    membership: MemberMembershipDataType;
    coachName: string;
    memberName: string;
    type: 'upgrade' | 'refund';
};

const ModalRefundAndUpgrade = ({ setIsVisible, membership, coachName, memberName, type }: Props) => {
    const queryClient = useQueryClient();

    const { patchMembershipRefundMutation, patchMembershipUpgradeMutation } = useMembershipPaymentsMutationService({
        onPatchMembershipRefundSuccess() {
            //getMembershipForMemberQuery
            queryClient.invalidateQueries({
                queryKey: ['getMembershipForMemberQuery'],
            });
            setIsVisible(false);
        },
        onPatchMembershipUpgradeSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['getMembershipForMemberQuery'],
            });
            setIsVisible(false);
        },
    });

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    // 환불결제일자 div ref
    const divRef = React.useRef<HTMLDivElement>(null);
    // div 위치
    const [divPosition, setDivPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        if (divRef.current) {
            const rect = divRef.current.getBoundingClientRect();
            setDivPosition({ top: rect.top + rect.height, left: rect.left });
        }
    }, [divRef]);

    const membershipId = membership._id;
    const title = type === 'refund' ? '수강권 환불' : '수강권 업그레이드';
    const isActive = membership?.status === 'active';
    // 총회차, 현재회차, 남은회차, 수업단가, 결제금액
    const totalsession = membership?.totalSession;
    const currentSession = membership?.currentSession;
    const remainingSession = membership?.remainSession;
    const pricePerSchedule = membership?.pricePerSchedule;
    const totalPrice = membership?.totalPrice;

    const [session, setSession] = useState(0);
    const [price, setPrice] = useState(0);
    const [date, setDate] = useState(dayjs().toISOString());

    // 환불회차가 남은회차 넘었을때 오류 여부
    const [isSessionError, setIsSessionError] = useState(false);

    const sessionTitle = type === 'refund' ? '환불 등록 회차' : '추가 등록 회차';
    const priceTitle = type === 'refund' ? '환불 결제 금액' : '추가 결제 금액';
    const dateTitle = type === 'refund' ? '환불 결제 일자' : '추가 결제 일자';
    const buttonTitle = type === 'refund' ? '환불 설정 완료' : '업그레이드 설정 완료';

    const onClickConfirm = () => {
        const param = {
            membershipId,
            session,
            amount: price,
            date,
        };

        if (type === 'refund') {
            patchMembershipRefundMutation.mutate({
                ...param,
            });
        } else {
            patchMembershipUpgradeMutation.mutate({
                ...param,
            });
        }
    };

    return (
        <Modal
            setIsVisible={setIsVisible}
            whiteViewWidth={825}
            borderRadius={25}
            isCloseOutside={false}
            isCloseButton
            modalCommpont={
                isCalendarOpen ? (
                    <NewCalendar
                        date={dayjs(date)}
                        selectedDay={dayjs(date)}
                        setIsCalendar={setIsCalendarOpen}
                        onClickDate={(day) => {
                            setDate(dayjs(day).toISOString());
                        }}
                        isAllDate={false}
                        top={divPosition.top - 500}
                        left={divPosition.left}
                        isNextMonth
                        isPrevMonth
                        titleFormat="YYYY년 MM월"
                    />
                ) : undefined
            }
            onClose={() => {
                setIsVisible(false);
            }}
        >
            <Col className="flex w-full h-full p-[35px]">
                <Row className="flex items-center mb-[57px]">
                    <BoldText fontSize={28} lineHeight={38} color="#3D4244" className="mr-[31px]">
                        {title}
                    </BoldText>
                    <MediumText
                        fontSize={23}
                        lineHeight={38}
                        color="#3D4244"
                        className="mr-[12px]"
                        letterSpacing={-0.3}
                    >
                        {coachName} 강사
                    </MediumText>
                    <SvgIcon
                        name={'SvgRightStrokeArrow'}
                        size={20}
                        fill={colors.trans}
                        stroke="#3D4244"
                        strokeWidth={1.5}
                    />
                    <MediumText
                        fontSize={23}
                        lineHeight={38}
                        color="#3D4244"
                        className="ml-[12px]"
                        letterSpacing={-0.3}
                    >
                        {memberName} 회원
                    </MediumText>
                </Row>
                <Row className="flex justify-between w-full items-center mb-[51px]">
                    <Col className="flex flex-col w-full items-center">
                        <DemiLightText fontSize={16} lineHeight={22} color="#3D4244" className="mb-[20px]">
                            현재 수강권 정보
                        </DemiLightText>
                        <Col
                            className="flex flex-col w-full rounded-tl-[12px] rounded-tr-[12px] h-[88px]  justify-center items-center "
                            style={{
                                background: isActive
                                    ? `linear-gradient(135deg, ${membership?.BGColor[0]} 0%, ${membership?.BGColor[1]} 100%)`
                                    : colors.GREY_200,
                                alignItems: 'center',
                            }}
                        >
                            <Col className="flex w-full h-full pt-[16px] pb-[21px] pl-[24px] justify-between items-start">
                                <Body2Bold className="text-LAVEL_0">{`${membership?.name}`}</Body2Bold>
                            </Col>
                        </Col>
                        <Col className="flex border border-solid border-[#E5E5E9] pl-[29px] pr-[21px] py-[31px] justify-between rounded-bl-[12px] rounded-br-[12px] h-[250px] w-full">
                            <Row className="flex justify-between w-full">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    총 회차
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {totalsession}회
                                </RegularText>
                            </Row>
                            <Row className="flex justify-between w-full">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    현재 회차
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {currentSession}회
                                </RegularText>
                            </Row>
                            <Row className="flex justify-between w-full">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    남은 회차
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {remainingSession}회
                                </RegularText>
                            </Row>
                            <Row className="flex justify-between w-full">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    수업 단가
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {convertToKRW(pricePerSchedule)}
                                </RegularText>
                            </Row>
                            <Row className="flex justify-between w-full">
                                <DemiLightText fontSize={16} lineHeight={23} color="#505967">
                                    결제 금액
                                </DemiLightText>
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {convertToKRW(totalPrice)}
                                </RegularText>
                            </Row>
                        </Col>
                    </Col>
                    <Col className="flex flex-col w-[1px] h-[375px] bg-[#D9D9D9] mx-[41px]" />
                    <Col className="flex flex-col w-full h-[309px] justify-between">
                        <Col>
                            <Row className="items-center mb-[12px]">
                                <RegularText fontSize={16} lineHeight={23} color="#505967" className=" mr-[12px]">
                                    {sessionTitle}
                                </RegularText>
                                <RegularText fontSize={12} lineHeight={17} color={isSessionError ? 'red' : '#505967'}>
                                    {isSessionError ? '환불회차가 남은회차를 초과할 수 없습니다.' : ''}
                                </RegularText>
                            </Row>
                            <TextInput
                                value={session}
                                onChange={(value) => {
                                    // 남은 회차 초과 입력 방지
                                    if ((value as number) > remainingSession && type === 'refund') {
                                        setIsSessionError(true);
                                        return;
                                    } else {
                                        setSession(value as number);
                                    }
                                }}
                                unit="회"
                                placeholder="0"
                                width={116}
                                error={isSessionError}
                            />
                        </Col>
                        <Col>
                            <RegularText fontSize={16} lineHeight={23} color="#505967" className="mb-[12px]">
                                {priceTitle}
                            </RegularText>
                            <TextInput
                                value={price}
                                onChange={(value) => {
                                    setPrice(value as number);
                                }}
                                unit="원"
                                placeholder="0"
                                width={116}
                            />
                        </Col>
                        <Col ref={divRef} className="flex flex-col">
                            <RegularText fontSize={16} lineHeight={23} color="#505967" className="mb-[12px]">
                                {dateTitle}
                            </RegularText>
                            <Col
                                className={`flex  border  border-solid h-[46px] justify-center rounded-[10px] bg-white px-[18px] cursor-pointer hover:border-primary transition-colors duration-300 ease-in-out ${
                                    isCalendarOpen ? 'border-primary' : 'border-[#D9D9D9B2]'
                                }`}
                                onClick={() => {
                                    setIsCalendarOpen(true);
                                }}
                            >
                                <RegularText fontSize={16} lineHeight={23} color="#3D4244">
                                    {dayjs(date).format('YYYY-MM-DD')}
                                </RegularText>
                            </Col>
                        </Col>
                    </Col>
                </Row>
                <Col
                    className="flex justify-center items-center self-end w-[214px] h-[71px]  bg-primary rounded-[9px] cursor-pointer"
                    onClick={onClickConfirm}
                >
                    <RegularText fontSize={20} lineHeight={23} color="white">
                        {buttonTitle}
                    </RegularText>
                </Col>
            </Col>
        </Modal>
    );
};
export default ModalRefundAndUpgrade;

type TextInputProps = {
    value: string | number;
    onChange: (value: string | number) => void;
    unit?: string; // 단위 (예: %, 원)
    placeholder?: string; // 플레이스홀더 텍스트
    width?: number; // 입력 필드 너비
    disabled?: boolean; // 비활성화 여부
    onBlur?: () => void;
    error?: boolean;
};

const TextInput: React.FC<TextInputProps> = ({
    value,
    onChange,
    unit = '',
    placeholder = '',
    width = 116,
    disabled = false,
    onBlur,
    error = false,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    // 포커스
    const [isFocused, setIsFocused] = useState(false);
    //
    return (
        <div
            className={`flex items-center border  border-solid h-[46px] rounded-[10px] bg-white px-[18px] cursor-text hover:border-primary transition-colors duration-300 ease-in-out ${
                disabled ? 'bg-gray-100 text-gray-400' : 'bg-white'
            } ${isFocused ? 'border-primary' : error ? 'border-red' : 'border-[#D9D9D9B2]'}`}
            onClick={() => {
                inputRef.current?.focus();
            }}
        >
            <input
                ref={inputRef}
                type="text"
                value={value}
                onChange={(e) => {
                    // 숫자입력시 0으로 시작하는 경우 0 제거
                    if (e.target.value.length > 1 && e.target.value[0] === '0') {
                        onChange(e.target.value.slice(1));
                        return;
                    }

                    // setText(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={placeholder}
                className={`flex flex-grow outline-none w-[80px] text-[16px] leading-[23px] text-[#3D4244]  ${
                    disabled ? 'cursor-not-allowed' : ''
                }`}
                // onFocus={() => setIsFocused(true)}
                // onBlur={() => {
                //     // onChange(text);
                //     setIsFocused(false);
                // }}
                onBlur={onBlur}
                disabled={disabled}

                // 숫자만
            />
            {unit && <DemiLightText className="flex">{unit}</DemiLightText>}
        </div>
    );
};
