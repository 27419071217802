import React, { useState } from 'react';
import { Col, getItemWithExpiration, Row, truncateString } from '../lib/utils';
import { colors } from '../lib/colors';
import MemberListHeader from '../components/MemberList/MemberListHeader';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    CenterMember,
    getCenterMemberListAPI,
    GetCenterMemberListRemainingPeriod,
    GetCenterMemberListRemainingSessions,
    GetCenterMemberListResponse,
    GetCenterMemberListStatus,
} from '../api/center';
import { CodeValueType } from '../types/types';
import Grid, { GridColProps } from '../components/Grid/Grid';
import { Body2Regular, Body3Regular, CaptionLight, Subtitle1Bold } from '../lib/font';
import dayjs from 'dayjs';
import ModalAlert from '../components/modal/ModalAlert';
import SvgIcon from '../components/SvgIcon';
import WhiteSquareButton from '../components/Button/WhiteSquareButton';
import ColorSquareButton from '../components/Button/ColorSquareButton';
import { useNavigate } from 'react-router-dom';
import TrashButton from '../components/Button/TrashButton';
import useCenterMutationService from '../services/useCenterMutationService';
import { I_CIRCLE_EXIT, I_WHITE_CHECK } from '../types/images';
import FloatingButton from './ClassPass/FloatingButton';
import useMemberMutationService from '../services/useMemberMutationService';

const MemberlistPage = ({ createAlert }: { createAlert: any }) => {
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { deleteTempMemberMutation } = useMemberMutationService({});

    // 선택삭자 모달 상태값
    const [isVisibleDeleteMember, setIsVisibleDeleteMember] = useState<boolean>(false);

    // #region 필터 상태값
    // 회원 리스트
    const [memberList, setMemberList] = useState<CenterMember[]>([]);
    const [selectedMemberList, setSelectedMemberList] = useState<CenterMember[]>([]);

    // 회원의 상태
    const [memberStatus, setMemberStatus] = useState<CodeValueType<GetCenterMemberListStatus>>({
        code: 'all',
        value: '회원 상태',
    });
    // 수강권 잔여기간
    const [remainingPeriod, setRemainingPeriod] = useState<CodeValueType<GetCenterMemberListRemainingPeriod>>({
        code: 'all',
        value: '잔여 기간',
    });
    // 수강권 잔여 횟수
    const [remainingSessions, setRemainingSessions] = useState<CodeValueType<GetCenterMemberListRemainingSessions>>({
        code: 'all',
        value: '잔여 횟수',
    });
    // 강사 이름
    const [coach, setCoach] = useState<CodeValueType>({
        code: 'all',
        value: '전체',
    });
    // #endregion

    // 초기화 버튼
    const onClickInit = () => {
        // 상태값들 초기로
        setMemberStatus({
            code: 'all',
            value: '회원 상태',
        });
        setRemainingPeriod({
            code: 'all',
            value: '잔여 기간',
        });
        setRemainingSessions({
            code: 'all',
            value: '잔여 횟수',
        });
        setCoach({
            code: 'all',
            value: '전체',
        });
    };
    //  강사 이름 조회 : (GET)
    const getCenterMemberListAPIQuery = useQuery(
        [
            'getCenterMemberListAPIQuery',
            authObject?.centerId, // centerId
            memberStatus.code, // status
            remainingPeriod.code, // remainingPeriod
            remainingSessions.code, // remainingSessions
            coach.code, // assignedCoach
            authorization, // accessToken
        ],
        async () =>
            await getCenterMemberListAPI(
                authObject?.centerId, // centerId
                memberStatus.code, // status
                remainingPeriod.code, // remainingPeriod
                remainingSessions.code, // remainingSessions
                coach.code, // assignedCoach
                authorization // accessToken
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const data: GetCenterMemberListResponse = res?.data;
                    const centerMemberList = data.centerMemberList;
                    setMemberList(centerMemberList);

                    // coachList.coachId 는 Code 로, coachList.name 은 Value 정제
                }
            },

            enabled: !!authorization,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );
    //
    const { patchDisconnectMembersMutation } = useCenterMutationService({
        onPatchDisconnectMembersSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['getCenterMemberListAPIQuery'],
            });
        },
        onPatchDisconnectMemberError() {},
    });

    // 회원 삭제
    const onDeleteSelectedMember = async () => {
        const tempMemberList = selectedMemberList.filter((member) => member.isConnected === false);
        const memberList = selectedMemberList.filter((member) => member.isConnected === true);

        const tempMemberPromises = tempMemberList.map((member) =>
            deleteTempMemberMutation.mutateAsync({
                centerId: authObject.centerId,
                memberId: member.memberId,
                accessToken: authorization,
            })
        );

        const memberPromises = patchDisconnectMembersMutation.mutateAsync({
            centerId: authObject?.centerId,
            memberIds: memberList.map((member) => member.memberId),
            accessToken: authorization,
        });

        try {
            // Promise.all로 동시에 실행
            const [tempResults, memberResults] = await Promise.all([
                Promise.all(tempMemberPromises), // 임시회원 삭제 결과
                memberPromises, // 등록회원 삭제 결과
            ]);

            // 두 작업이 모두 성공한 경우에만 조회 수행
            queryClient.invalidateQueries({
                queryKey: ['getCenterMemberListAPIQuery'],
            });
            createAlert('회원 삭제가 완료되었습니다.', '회원 삭제가 완료되었습니다.', false);
        } catch (error) {
            // 실패에 대한 처리 추가 (선택사항)
        }
    };
    // 임시회원 등록
    const onRegisterTemporaryMember = () => {
        navigate('/member/temporary');
    };

    // #region 그리드 컬럼
    const [colDefs, setColDefs] = useState<GridColProps[]>([
        {
            field: 'name',
            headerName: '회원이름',
            align: 'center',
            // sticky: true,
            width: 132,
            isSort: true,
        },
        {
            field: 'status',
            headerName: '상태(담당강사)',
            customFormat: (value, row) => {
                return value === 'active'
                    ? `활성 (${row.coachName})`
                    : value === 'expired'
                    ? `만료 (${row.coachName})`
                    : value === 'none'
                    ? `대기 (${row.coachName})`
                    : `비활성 (${row.coachName})`;
            },
            align: 'center',
            // sticky: true,
            width: 168,
        },
        {
            field: 'phoneNumber',
            headerName: '휴대폰 번호',
            width: 188,
            nullValue: '없음',
            align: 'center',
        },
        {
            field: 'gender',
            headerName: '나이(성별)',
            customFormat: (value, row) =>
                `${row?.age ? row.age : '-'} (${value ? (value === 'male' ? '남성' : '여성') : '-'})`,
            align: 'center',
            width: 128,
        },
        {
            field: 'created',
            headerName: '등록일',
            type: 'date',
            dateFormat: 'YYYY / MM / DD',
            align: 'center',
            isSort: true,
            width: 168,
        },
        {
            field: 'lastScheduleCreated',
            headerName: '최근 수업일',
            type: 'date',
            dateFormat: 'YYYY / MM / DD',
            align: 'center',
            nullValue: '수업 없음',
            isSort: true,
            width: 168,
        },
        {
            field: 'memberships',
            headerName: '활성 수강권',
            customComponent: (value, row) => {
                return value.map((membership: any) => {
                    return (
                        <Col style={{ marginBottom: 8 }}>
                            <Row>
                                <Body3Regular>{truncateString(membership.name, 30)}</Body3Regular>
                            </Row>
                            <Row style={{ justifyItems: 'center', alignItems: 'center' }}>
                                <Body3Regular>
                                    {`현재 회차 : ${membership.currentSession} / ${membership.totalSession}`}
                                </Body3Regular>
                                <Col className="w-1 h-1 bg-black rounded-full mx-[12px]" />
                                <Body3Regular>
                                    {`유효기간 : ${dayjs(membership.expirationDate).diff(dayjs(), 'day')}일`}
                                </Body3Regular>
                            </Row>
                        </Col>
                    );
                });
            },
        },
        {
            field: 'isConnected',
            headerName: '앱 연결',
            align: 'center',
            width: 132,
            isSort: false,
            customComponent: (value, row) => {
                return value ? (
                    <Row
                        style={{
                            width: 18,
                            height: 18,
                            borderRadius: 9,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: colors.BLUE_500,
                            margin: '0 auto', // 가로 중앙 정렬
                        }}
                    >
                        <img src={I_WHITE_CHECK} style={{ width: 16, height: 16 }} />
                    </Row>
                ) : (
                    <img
                        src={I_CIRCLE_EXIT}
                        style={{
                            width: 24,
                            height: 24,
                            margin: '0 auto', // 가로 중앙 정렬
                        }}
                    />
                );
            },
        },
    ]);
    // #endregion

    return (
        <Col
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: colors.white,
                marginTop: 64,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <MemberListHeader
                coach={coach}
                remainingPeriod={remainingPeriod}
                remainingSessions={remainingSessions}
                memberStatus={memberStatus}
                onMemberStatusChange={(item) => {
                    setMemberStatus(item);
                }}
                onRemainingPeriodChange={(item) => {
                    setRemainingPeriod(item);
                }}
                onRemainingSessionsChange={(item) => {
                    setRemainingSessions(item);
                }}
                onCoachChange={(item) => {
                    setCoach(item);
                }}
                onClickInit={onClickInit}
            />

            <Col style={{ width: 'calc(100% - 128px)' }}>
                <Grid
                    colDefs={colDefs}
                    rowData={memberList}
                    onClickRow={(row) => {
                        navigate('/member/detail', { state: { memberId: row.memberId } });
                    }}
                    onSortedData={(data: any[]) => {
                        setMemberList(data);
                    }}
                    isCheckColumn
                    isAllCheckColumn={false}
                    // 왼쪽 상단
                    leftTopCustomComponent={(filterData) => {
                        return (
                            <CaptionLight color={colors.LAVEL_1}>
                                {`회원 총 ${memberList?.length}명 / 검색결과 : ${filterData?.length}명`}
                            </CaptionLight>
                        );
                    }}
                    // setSelectedMemberList(selectedData);
                    // setIsVisibleDeleteMember(true);
                    leftBottomCustomComponent={(selectedData) => {
                        return (
                            <TrashButton
                                text="선택 삭제"
                                onClick={() => {
                                    setSelectedMemberList(selectedData);
                                    setIsVisibleDeleteMember(true);
                                }}
                            />
                        );
                    }}
                    // 가운데 상단
                    centerTopComponent="pagination"
                    // 오른쪽 상단
                    rightTopComponent="textInput"
                    // 오른쪽 하단
                    rightBottomComponent="dropdown"
                    textInputPlaceholder="내용을 입력해주세요"
                    mb={200}
                />
            </Col>
            {isVisibleDeleteMember && (
                <ModalAlert
                    modalVisible={isVisibleDeleteMember}
                    setModalVisible={setIsVisibleDeleteMember}
                    customComponent={
                        <Col className="justify-center items-center w-[540px] h-[346px]">
                            <SvgIcon name={'SvgError'} fill="red" style={{ marginBottom: 8 }} />
                            <Subtitle1Bold style={{ color: colors.LAVEL_4, marginBottom: 12 }}>
                                선택 회원 삭제
                            </Subtitle1Bold>
                            <Body2Regular style={{ marginBottom: 16 }}>선택된 회원이 모두 삭제됩니다!</Body2Regular>
                            <Col className="p-[16px] bg-WHITE_500 rounded-[8px] mb-[16px] w-full">
                                <Body3Regular className="mb-[4px]">
                                    1. 삭제후, 회원 검색 및 복구가 불가능합니다!
                                </Body3Regular>
                                <Body3Regular>2. 삭제된 회원의 수강권은 자동으로 만료처리 됩니다!</Body3Regular>
                            </Col>
                            <Row>
                                <WhiteSquareButton
                                    text={'취소'}
                                    size="middle"
                                    mr={8}
                                    callBack={() => {
                                        setIsVisibleDeleteMember(false);
                                    }}
                                />
                                <ColorSquareButton
                                    text={'삭제'}
                                    size="middle"
                                    callBack={() => {
                                        onDeleteSelectedMember();
                                        setIsVisibleDeleteMember(false);
                                    }}
                                />
                            </Row>
                        </Col>
                    }
                />
            )}
            <FloatingButton onClick={onRegisterTemporaryMember} />
        </Col>
    );
};

export default MemberlistPage;
