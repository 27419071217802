import React, { useEffect, useRef, useState } from 'react';
import { MemberClassHistorySchedule, MonthlySchedule, YearSchedule } from '../../services/useCenterQueryService';
import Grid, { GridColProps } from '../Grid/Grid';
import dayjs from 'dayjs';
import { Col, convertHourMin } from '../../lib/utils';
import { CaptionRegular } from '../../lib/font';
import { PeriodNavigatorType } from '../Date/PeriodNavigator';
import MultiGrid from '../Grid/MultiGrid';

type Props = {
    type: PeriodNavigatorType;
    currentDate: string;
    monthlyData?: MemberClassHistorySchedule[];
    yearlyData?: YearSchedule;
    totalData?: YearSchedule[];
    onClickRow?: (row: MemberClassHistorySchedule) => void;
};

const MemberClassHistoryComponentsGrid = ({
    monthlyData,
    yearlyData,
    totalData,
    type,
    currentDate,
    onClickRow,
}: Props) => {
    const [colDefs, setColDefs] = useState<GridColProps[]>([
        {
            field: 'startTime',
            headerName: '날짜',
            align: 'center',
            type: 'date',
            dateFormat: 'M월 D일 (ddd)',
            width: 178,
        },
        {
            field: 'endTime',
            headerName: '수업시간',
            align: 'center',
            customFormat: (value, row) => {
                return `${convertHourMin(row.startTime, 'text', true)} ~ ${convertHourMin(
                    row.endTime,
                    'text',
                    true
                )}  (${dayjs(row.endTime).diff(row.startTime, 'minute')}분)`;
            },
            width: 368,
        },
        {
            field: 'membershipName',
            headerName: '수강권',
            align: 'center',
            width: 368,
            customFormat: (value, row) => {
                return row?.status === 'ATTENDANCE' || row?.membershipId ? value : '-';
            },
        },
        {
            field: 'currentSession',
            headerName: '회차',
            align: 'center',
            customFormat: (value, row) => {
                return row?.membershipId && row?.membershipName ? `${row.currentSession} / ${row.totalSession}회` : '-';
            },
            width: 128,
        },
        {
            field: 'attendanceStatus',
            headerName: '상태',
            align: 'center',
            width: 150,
            booleanComponent: {
                fn: (value) => {
                    return !value.attendanceStatus || value.attendanceStatus === 'ATTENDANCE' || !value.membershipId
                        ? true
                        : false;
                },
                trueComponent: (
                    <div className="flex w-full justify-center">
                        <Col className="flex flex-col justify-center bg-SUCCESS_50 w-[46px] h-[30px] rounded-[4px]">
                            <CaptionRegular className="text-SUCCESS">출석</CaptionRegular>
                        </Col>
                    </div>
                ),
                falseComponent: (
                    <div className="flex w-full justify-center">
                        <Col className="flex flex-col justify-center bg-ERROR_50 w-[46px] h-[30px] rounded-[4px]">
                            <CaptionRegular className="text-ERROR">노쇼</CaptionRegular>
                        </Col>
                    </div>
                ),
            },
        },
    ]);

    return (
        <div className="flex w-[1192px] mb-[300px]">
            {type === 'thisMonth' && monthlyData && (
                <Grid rowData={monthlyData} colDefs={colDefs} isDisableHover={true} onClickRow={onClickRow} />
            )}
            {type === 'all' && (
                <MultiGrid
                    colDefs={colDefs}
                    rowData={totalData}
                    currentYear={dayjs(currentDate).format('YYYY')}
                    type={type}
                    onClickRow={onClickRow}
                />
            )}
            {type === 'thisYear' && (
                <MultiGrid
                    colDefs={colDefs}
                    rowData={yearlyData}
                    currentYear={dayjs(currentDate).format('YYYY')}
                    type={type}
                    onClickRow={onClickRow}
                />
            )}
        </div>
    );
    // return <div></div>;
};

export default MemberClassHistoryComponentsGrid;
